var climbGame;
var game;

var climbWall = (json) => {
    (function (climbGame) {
        class App extends Phaser.Game {
            constructor(json) {
                App.json = json;
                let config = {
                    type: Phaser.AUTO,
                    width: App.CANVAS_WIDTH,
                    height: App.CANVAS_HEIGHT,
                    parent: 'game-container',
                    dom: {
                        createContainer: false
                    },
                    scale: {
                        mode: FitScaleManager.detectBestScaleMode(),
                        autoCenter: Phaser.Scale.CENTER_BOTH
                    },
                    transparent: true,
                    scene: {
                        create: () => {
                            this.scene.add('Boot', climbGame.scene.Boot, true);
                        }
                    }
                };
                super(config);
            }
        }
        App.CANVAS_WIDTH = 980;
        App.CANVAS_HEIGHT = 600;
        climbGame.App = App;
    })(climbGame || (climbGame = {}));

    var delayedCalls = [];
    function delayedCall(delay, callback, args, callbackScope) {
        let scene = game.scene.getAt(0);
        if (scene) {
            let dc = scene.time.delayedCall(delay, callback, args, callbackScope);
            delayedCalls.push(dc);
            return dc;
        }
        let t = setTimeout(callback, delay);
        delayedCalls.push(t);
        return t;
    }
    function pauseAllDelayedCalls() {
        for (let dc of delayedCalls) {
            if (dc instanceof Phaser.Time.TimerEvent) {
                dc.paused = true;
            }
        }
    }
    function resumeAllDelayedCalls() {
        for (let dc of delayedCalls) {
            if (dc instanceof Phaser.Time.TimerEvent) {
                dc.paused = false;
            }
        }
    }
    function destroyAllDelayedCalls() {
        for (let dc of delayedCalls) {
            if (dc instanceof Phaser.Time.TimerEvent) {
                dc.remove(false);
            }
            else {
                clearTimeout(dc);
            }
        }
        delayedCalls = [];
    }
    function destroyDelayedCall(dc) {
        if (dc instanceof Phaser.Time.TimerEvent) {
            dc.remove(false);
        }
        else {
            clearTimeout(dc);
        }
        let ind = delayedCalls.indexOf(dc);
        if (ind >= 0) delayedCalls.splice(ind, 1);
    }
    function setPageBackground(bg) {
        // document.querySelector("html").style.backgroundImage = "url(assets/imgs/" + bg + ".jpg)";
    }
    function setupButton(btn, frame) {
        btn.on('pointerdown', () => { btn.setFrame(frame + '_hover' + '0000'); });
        btn.on('pointerover', () => { btn.setFrame(frame + '_hover' + '0000'); game.scene.getAt(0).sound.add("button hover").play(); });
        btn.on('pointerout', () => { btn.setFrame(frame + '0000'); });
        btn.on('pointerup', () => { btn.setFrame(frame + '0000'); game.scene.getAt(0).sound.add('activity selection - button selection').play(); });
    }
    function setupButtonTextureBased(btn, texture, hoverTexture) {
        btn.on('pointerdown', () => { btn.setTexture(hoverTexture); });
        btn.on('pointerover', () => { btn.setTexture(hoverTexture); game.scene.getAt(0).sound.add("button hover").play(); });
        btn.on('pointerout', () => { btn.setTexture(texture); });
        btn.on('pointerup', () => { btn.setTexture(texture); game.scene.getAt(0).sound.add('activity selection - button selection').play(); });
    }
    function playBtnClickAnim(target) {
        let sc = target.hasOwnProperty("defScale") ? target["defScale"] : 1;
        target.scaleX = target.scaleY = sc;
        let scene = game.scene.getAt(0);
        scene.tweens.add({
            targets: target,
            "scaleX": 0.9 * sc,
            "scaleY": 0.9 * sc,
            duration: 100,
            yoyo: true
        });
    }

    class FitScaleManager {
        constructor(game) {
            this.doResize = () => {
                let scale = this.calculateScale();
                let newCanvasWidth = this.phaserScaleManager.width * scale;
                let newCanvasHeight = this.phaserScaleManager.height * scale;
                this.canvasStyle.width = newCanvasWidth + 'px';
                this.canvasStyle.height = newCanvasHeight + 'px';
                this.canvasStyle.marginLeft = (window.innerWidth - newCanvasWidth) / 2 + 'px';
                this.canvasStyle.marginTop = (window.innerHeight - newCanvasHeight) / 2 + 'px';
            };
            this.game = game;
            this.canvasStyle = this.game.canvas.style;
            this.phaserScaleManager = this.game.scale;
        }
        static detectBestScaleMode() {
            let iOS = /iPad|iPhone|iPod/.test(navigator.platform || "");
            let isAndroid = window.navigator.userAgent.toLowerCase().indexOf("android") > -1;
            return iOS || isAndroid ? Phaser.Scale.FIT : Phaser.Scale.NONE;
        }
        ;

        setup() {
            this.phaserScaleManager.addListener(Phaser.Scale.Events.RESIZE, this.onResize, this);
            this.overridePhaserTransformMethods();
            this.onResize();
        }
        calculateScale() {
            if (game.scale.scaleMode == Phaser.Scale.NONE) return 1;
            return Math.min(window.innerWidth / this.phaserScaleManager.width, window.innerHeight / this.phaserScaleManager.height);
        }
        overridePhaserTransformMethods() {
            this.game.scale.transformX = (pageX) => {
                return (pageX - parseInt(this.canvasStyle.marginLeft.split("px")[0])) / this.calculateScale();
            };
            this.game.scale.transformY = (pageY) => {
                return (pageY - parseInt(this.canvasStyle.marginTop.split("px")[0])) / this.calculateScale();
            };
        }
        onResize() {
            setTimeout(this.doResize, FitScaleManager.RESIZE_DELAY);
        }
    }
    FitScaleManager.RESIZE_DELAY = 500;

    (function (climbGame) {
        var core;
        (function (core) {
            class Gameplay {
                constructor() {
                    const json = game.cache.json.get('gameplay');

                    this.rounds = json["rounds"].slice();
                    this.totalRoundsNum = this.rounds.length;
                    this.currentRound = 0;
                    this.correctAnswersCount = 0;
                    this.wrongAnswersCount = 0;
                    this.correctAnswersCountThisRound = 0;
                    this.wrongAnswersCountThisRound = 0;
                    this.onNewRound = null;
                    this.nextLetterDelay = 0;
                    this.words = json['words'];
                    this.failsNumToLose = Number(json["failsNumToLose"]);
                    this.useImages = Boolean(json["useImages"]);
                }

                setupCallbacks(onComplete, onLose, onNewRound, onRepeatRound) {
                    this.onComplete = onComplete;
                    this.onLose = onLose;
                    this.onNewRound = onNewRound;
                    this.onRepeatRound = onRepeatRound;
                }
                calculateScore() {
                    return this.totalRoundsNum - this.wrongAnswersCount;
                }
                onLetterChosen(correct) {
                    this.currentRound++;
                    if (this.currentRound >= this.totalRoundsNum) {
                        let score = this.calculateScore();
                        if (correct) {
                            setTimeout(() => this.onComplete(score, score), 13000);
                        } else {
                            this.onComplete(score, score);
                        }
                        return true;
                    } else {
                        const repeatSameLetters = !correct;
                        this.nextLetter(repeatSameLetters);
                    }
                    return false;
                }
                nextLetter(repeatSameRound) {

                    let fn = () => {
                        if (repeatSameRound) {
                            if (this.onRepeatRound) this.onRepeatRound();
                            return;
                        }
                        let thisRound = repeatSameRound ? this.rounds[0] : this.rounds.shift();
                        this.wantedWord = thisRound["wantedWord"];
                        this.selectableWords = thisRound["selectableWords"];
                        this.selectableImages = [];
                        this.correctAnswersCountThisRound = 0;
                        this.wrongAnswersCountThisRound = 0;
                        this.words.map(word => {
                            if (this.selectableWords.includes(word.word)) {
                                this.selectableImages[this.selectableWords.indexOf(word.word)] = word.image
                            }
                            if (word.word === this.wantedWord[0]) {
                                this.wantedImage = word.image;
                            }
                        });
                        if (this.onNewRound)
                            this.onNewRound();
                    };
                    if (this.nextLetterDelay == 0) {
                        fn();
                    }
                    else {
                        delayedCall(this.nextLetterDelay, fn);
                    }
                }
                onCorrectAnswer() {
                    this.correctAnswersCount++;
                    this.correctAnswersCountThisRound++;
                    this.nextLetterDelay = 1800;
                    const result = this.onLetterChosen(true);
                    return result;
                }
                onWrongAnswer() {
                    this.wrongAnswersCount++;
                    this.wrongAnswersCountThisRound++;
                    this.nextLetterDelay = 1500;
                    if (this.wrongAnswersCount >= this.failsNumToLose) {
                        this.onLose(0, 0);
                        return true;
                    }
                    else {
                        this.onLetterChosen(false);
                    }
                    return false;
                }
                getCurrentTotalAnswersCount() {
                    return this.correctAnswersCount + this.wrongAnswersCount;
                }
                getCurrentTotalAnswersCountThisRound() {
                    return this.correctAnswersCountThisRound + this.wrongAnswersCountThisRound;
                }
                isNewRound() {
                    return this.getCurrentTotalAnswersCountThisRound() === 0;
                }
                isRoundsComplete() {
                    return this.currentRound >= this.totalRoundsNum;
                }
                getAudioKeyOfChar(letter) {
                    let json = game.cache.json.get('gameplay');
                    let letters = json["letters"];
                    for (let d of letters) {
                        if (letter == d['letterName']) {
                            return d['audioKey'];
                        }
                    }
                    return null;
                }
                reset() {
                    this.nextLetterDelay = 0;
                    this.setupCallbacks(null, null, null, null);
                    let json = game.cache.json.get('gameplay');
                    this.rounds = json["rounds"].slice();
                    this.totalRoundsNum = this.rounds.length;
                    this.nextLetter();
                    this.currentRound = 0;
                    this.correctAnswersCount = 0;
                    this.wrongAnswersCount = 0;
                    this.correctAnswersCountThisRound = 0;
                    this.wrongAnswersCountThisRound = 0;
                }
            }
            core.Gameplay = Gameplay;
        })(core = climbGame.core || (climbGame.core = {}));
    })(climbGame || (climbGame = {}));

    (function (climbGame) {
        var scene;
        (function (scene) {
            class Boot extends Phaser.Scene {
                init() {
                    this.game.scale.transformX = (pageX) => {
                        let offsetLeft = 0;
                        let parentElement = game.canvas.parentElement;
                        while (parentElement) {
                            if (parentElement.offsetLeft) {
                                offsetLeft = parentElement.offsetLeft;
                                break;
                            }
                            parentElement = parentElement.parentElement;
                        }
                        return (pageX - offsetLeft) * this.game.scale.displayScale.x;
                    };
                    this.game.scale.transformY = (pageY) => {
                        let offsetTop = 0;
                        let parentElement = game.canvas.parentElement;
                        while (parentElement) {
                            if (parentElement.offsetTop) {
                                offsetTop = parentElement.offsetTop;
                                break;
                            }
                            parentElement = parentElement.parentElement;
                        }
                        return (pageY - offsetTop) * this.game.scale.displayScale.y;
                    };
                }
                create() {
                    game.scene.remove('Boot');
                    game.scene.add('Preloader', climbGame.scene.Preloader, true);
                }
            }
            scene.Boot = Boot;
        })(scene = climbGame.scene || (climbGame.scene = {}));
    })(climbGame || (climbGame = {}));

    (function (climbGame) {
        var scene;
        (function (scene) {
            var Gameplay = climbGame.core.Gameplay;
            class MainScene extends Phaser.Scene {
                create() {
                    this.gameplay = new Gameplay();
                    this.gameplayScreen = new climbGame.screen.GameplayScreen(this, this.gameplay);
                    this.children.add(this.gameplayScreen);
                    this.gameplayScreen.showInstructionPage();
                }
                update() {
                    this.gameplayScreen._update();
                }
            }
            scene.MainScene = MainScene;
        })(scene = climbGame.scene || (climbGame.scene = {}));
    })(climbGame || (climbGame = {}));

    (function (climbGame) {
        var scene;
        (function (scene) {
            class Preloader extends Phaser.Scene {
                preload() {
                    this.load.json('gameplay', climbGame.App.json);
                }
                create() {
                    let json = game.cache.json.get('gameplay');
                    if (json["useImages"]) {
                        for (let w of json["words"]) {
                            if (w["image"] && !w["image"].includes('http')) {
                                this.load.image(w["word"], "assets/games/climb-wall/imgs/words/" + w["image"] + ".png");
                            } else {
                                this.load.image(w["word"], w["image"]);
                            }
                            if (w["audioKey"]) {
                                this.load.audio(w["audioKey"], "assets/games/climb-wall/sound/mp3/words/" + w["audioKey"] + ".mp3");
                            }
                        }
                    }
                    let progressTxt = this.add.text(game.scale.width / 2, game.scale.height / 2 + 50, "", {
                        "fontFamily": "QuranErafont",
                        "fontSize": 25,
                        "color": "#000000",
                        "align": 'center'
                    });
                    progressTxt.setOrigin(0.5, 0.5);
                    this.load.pack('preloader', 'assets/games/climb-wall/pack.json');
                    this.load.on('progress', (value) => {
                        progressTxt.text = Math.ceil(value * 100) + "%";
                    }, this);
                    this.load.on('complete', () => {
                        this.nextScene();
                    });
                    this.load.start();
                }
                static playAnim(animKey, sprite, onComplete = null) {
                    let mainScene = game.scene.getScene('ScreenMain');
                    if (!mainScene.anims.exists(animKey)) {
                        let data = Preloader.ANIMS_DATA[animKey];
                        mainScene.anims.create({
                            key: animKey,
                            frames: mainScene.anims.generateFrameNames(data['atlas'], {
                                start: data['start'], end: data['end'], zeroPad: data['padNum'],
                                prefix: data['prefix'], suffix: data['suffix'] || ''
                            }),
                            frameRate: data['frameRate'],
                            repeat: data['repeat']
                        });
                    }
                    if (sprite.anims.currentAnim) {
                        sprite.anims.currentAnim.off('complete');
                    }
                    sprite.anims.stop();
                    sprite.play(animKey);
                    sprite.anims.currentAnim.once('complete', () => {
                        if (onComplete)
                            onComplete();
                    });
                    return sprite;
                }
                nextScene() {
                    game.scene.remove('Preloader');
                    game.scene.add('ScreenMain', climbGame.scene.MainScene, true);
                }
            }
            Preloader.ANIMS_DATA = {
                'climb-long': {
                    'start': 1,
                    'end': 72,
                    'padNum': 4,
                    'prefix': 'Climb v2',
                    'suffix': '.png',
                    'repeat': 0,
                    'frameRate': 36,
                    'atlas': 'climb'
                },
                'climb-short': {
                    'start': 1,
                    'end': 60,
                    'padNum': 4,
                    'prefix': 'Climb v2',
                    'suffix': '.png',
                    'repeat': 0,
                    'frameRate': 36,
                    'atlas': 'climb'
                },
                'adhan': {
                    'start': 1,
                    'end': 80,
                    'padNum': 4,
                    'prefix': 'adhan',
                    'suffix': '.png',
                    'repeat': 0,
                    'frameRate': 24,
                    'atlas': 'athan-v2-atlas'
                },
                'foot-slip': {
                    'start': 1,
                    'end': 53,
                    'padNum': 4,
                    'prefix': 'foot slip',
                    'suffix': '.png',
                    'repeat': 0,
                    'frameRate': 30,
                    'atlas': 'foot-slip-atlas'
                },
            };
            scene.Preloader = Preloader;
        })(scene = climbGame.scene || (climbGame.scene = {}));
    })(climbGame || (climbGame = {}));

    (function (climbGame) {
        var screen;
        (function (screen) {
            class AreYouSureWindow extends Phaser.GameObjects.Container {
                constructor(scene, onYes, onNo) {
                    super(scene);
                    this._areYouSurePage = new Phaser.GameObjects.Image(this.scene, -105, 0 - 48, 'Exit warning');
                    this._areYouSurePage.setOrigin(0, 0);
                    this._areYouSurePage.setInteractive();
                    this._btnSureYes = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2 - 95, 485 - 50, 'btnYES1');
                    this._btnSureYes.setInteractive({ cursor: 'pointer' });
                    this._btnSureYes.once('pointerup', () => {
                        onYes;
                        window.angularComponentRef.backToMenu();
                    });


                    var modalTextStyle = {
                        backgroundColor: 'transparent',
                        stroke: '#ffffff',
                        strokeThickness: 2,
                        lineSpacing: 15,
                        fontFamily: 'Kids Rock DEMO',
                        fontSize: 27,
                        color: '#43425d',
                        wordWrap: true,
                        align: 'center'
                    };
                    var modalText = this.scene.add.text(game.scale.width / 2 + 12, game.scale.height / 2, "Your progress in this activity will not be saved. \n Are you sure you want to exit? ", modalTextStyle);
                    modalText.setOrigin(0.5, 0.5);

                    setupButtonTextureBased(this._btnSureYes, 'btnYES1', 'btnYES2');
                    this._btnSureNo = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2 + 95, 485 - 50, 'btnNO1');
                    this._btnSureNo.setInteractive({ cursor: 'pointer' });
                    this._btnSureNo.once('pointerup', onNo);
                    setupButtonTextureBased(this._btnSureNo, 'btnNO1', 'btnNO2');
                    this.add(this._areYouSurePage);
                    this.add(this._btnSureYes);
                    this.add(this._btnSureNo);
                    this.add(modalText);
                }
            }
            screen.AreYouSureWindow = AreYouSureWindow;
        })(screen = climbGame.screen || (climbGame.screen = {}));
    })(climbGame || (climbGame = {}));

    (function (climbGame) {
        var screen;
        (function (screen) {
            class CompleteWindow extends Phaser.GameObjects.Container {
                constructor(scene, onBack, onReplay, onNext) {
                    super(scene);
                    this.music = null;
                    this.setPosition(-104.5, -48);
                    this._bgComplete = new Phaser.GameObjects.Image(this.scene, 0, 0, 'Completion page LATEST UPDATED');
                    this._bgComplete.setOrigin(0, 0);
                    this._bgComplete.setInteractive();
                    this._cup = new Phaser.GameObjects.Image(this.scene, 400, 410, 'Trophy');
                    this._btnBack = new Phaser.GameObjects.Image(this.scene, 570, 570, 'btnBACK1');
                    this._btnReplay = new Phaser.GameObjects.Image(this.scene, 720, 570, 'btnReplay1');
                    this._btnNext = new Phaser.GameObjects.Image(this.scene, 870, 570, 'btnNEXT1');
                    let _CollectedPoints = new Phaser.GameObjects.Image(this.scene, 620, 440, 'Collected Points');
                    this.totalScoreTxt = this.scene.add.text(845, 352, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 35,
                        "color": "#F49F1C",
                        "align": 'center',
                        'stroke': '#70451A',
                        'strokeThickness': 6
                    });
                    this.totalScoreTxt.setOrigin(0.5, 0.5);
                    let grd = this.totalScoreTxt.context.createLinearGradient(0, 0, 0, this.totalScoreTxt.height);
                    grd.addColorStop(0, '#FFFF00');
                    grd.addColorStop(1, '#C17316');
                    this.totalScoreTxt.setFill(grd);
                    this.starScoreTxt = this.scene.add.text(648, 433, "", {
                        "fontFamily": "Kids Rock DEMO",
                        "fontSize": 24,
                        "color": "#FFFFFF",
                        "align": 'center'
                    });
                    this.starScoreTxt.setOrigin(0.5, 0.5);
                    this.add([
                        this._bgComplete,
                        _CollectedPoints,
                        this._cup,
                        this._btnBack,
                        this._btnReplay,
                        this._btnNext,
                        this.totalScoreTxt,
                        this.starScoreTxt
                    ]);
                    this._btnBack.setInteractive({ cursor: 'pointer' });
                    this._btnBack.on('pointerup', () => {
                        onBack(this._btnBack);
                        window.angularComponentRef.backToMenu();
                        // if (this.music) {
                        //     this.music.stop();
                        // }
                    });
                    setupButtonTextureBased(this._btnBack, 'btnBACK1', 'btnBACK2');
                    this._btnReplay.setInteractive({ cursor: 'pointer' });
                    this._btnReplay.once('pointerup', () => {
                        onReplay(this._btnReplay);
                        if (this.music) {
                            this.music.stop();
                        }
                    });
                    setupButtonTextureBased(this._btnReplay, 'btnReplay1', 'btnReplay2');
                    this._btnNext.setInteractive({ cursor: 'pointer' });
                    this._btnNext.on('pointerup', () => {
                        onNext(this._btnNext);
                        window.angularComponentRef.nextActivity();
                        // if (this.music) {
                        //     this.music.stop();
                        // }
                    });
                    setupButtonTextureBased(this._btnNext, 'btnNEXT1', 'btnNEXT2');
                }
                show(score, starScore) {
                    window.angularComponentRef.gameFinish({ score, starScore });
                    this._cup.scale = 1.25;
                    this.scene.tweens.add({
                        targets: this._cup,
                        "scale": 1,
                        duration: 500,
                        ease: Phaser.Math.Easing.Back.Out
                    });
                    this.totalScoreTxt.text = String(window.angularComponentRef.component.userInfo.totalScore);
                    this.starScoreTxt.text = String(starScore);
                    // let music = this.scene.sound.add("viktory");
                    this.music = this.scene.sound.add("Activity completion fantastic");
                    this.music.play();
                }
            }
            screen.CompleteWindow = CompleteWindow;
        })(screen = climbGame.screen || (climbGame.screen = {}));
    })(climbGame || (climbGame = {}));

    (function (climbGame) {
        var screen;
        (function (screen) {
            var Preloader = climbGame.scene.Preloader;
            class GameplayScreen extends Phaser.GameObjects.Container {
                constructor(scene, gameplay) {
                    super(scene);
                    this.scrollableContainer = new Phaser.GameObjects.Container(this.scene);
                    this.add(this.scrollableContainer);
                    this.wordsContainer = new Phaser.GameObjects.Container(this.scene);
                    this.add(this.wordsContainer);
                    this.passedWordsContainer = new Phaser.GameObjects.Container(this.scene);
                    this.add(this.passedWordsContainer);
                    this.bgMusic = null;
                    this.wantedWindow = null;
                    this.wfsnd = null;
                    this.showCompleteWindow = (score, starScore) => {
                        let completeWindow = new screen.CompleteWindow(this.scene, (target) => {
                            playBtnClickAnim(target);
                        }, (target) => {
                            playBtnClickAnim(target);
                            this.destroyGameplay();
                            this.remove(completeWindow);
                            this.showInstructionPage();
                        }, (target) => {
                            playBtnClickAnim(target);
                        });
                        this.setInputEnabled(false);
                        delayedCall(2000, () => {
                            setPageBackground("bg-blue");
                            this.add(completeWindow);
                            completeWindow.show(score, starScore);
                            if (this.bgMusic) this.bgMusic.stop();

                            this.wordsContainer.removeAll();
                            this.passedWordsContainer.removeAll();
                        });
                    };
                    this.showLoseWindow = (score, starScore) => {
                        let tryAgainWindow = new screen.TryAgainWindow(this.scene, (target) => {
                            playBtnClickAnim(target);
                        }, (target) => {
                            playBtnClickAnim(target);
                            this.destroyGameplay();
                            this.remove(tryAgainWindow);
                            this.showInstructionPage();
                        });
                        this.setInputEnabled(false);
                        delayedCall(1500, () => {
                            setPageBackground("bg-blue");
                            this.add(tryAgainWindow);
                            tryAgainWindow.show(score, starScore);
                            if (this.bgMusic) this.bgMusic.stop();

                            this.wordsContainer.removeAll();
                            this.passedWordsContainer.removeAll();
                        });
                    };
                    this.gameplay = gameplay;
                    window["gs"] = this;


                    this.wallBottom = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2, game.scale.height / 2, 'BG1A');
                    this.wallBottom.setOrigin(0.5, 0.5);
                    this.wallBottom.setScale(1.004, 1);
                    this.wallBottom.setInteractive();
                    this.scrollableContainer.add(this.wallBottom);

                    this.wallTop = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2, -game.scale.height / 2, 'BG1B');
                    this.wallTop.setOrigin(0.5, 0.5);
                    this.wallTop.setScale(1.004, 1);
                    this.wallTop.setInteractive();
                    this.scrollableContainer.add(this.wallTop);

                    this.mosqueRoof = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2, -1.5 * game.scale.height, 'BG2');
                    this.mosqueRoof.setOrigin(0.5, 0.5);
                    this.mosqueRoof.setScale(1.004, 1);
                    this.mosqueRoof.setInteractive();
                    this.scrollableContainer.add(this.mosqueRoof);

                    this.charactersContainer = new Phaser.GameObjects.Container(this.scene);
                    this.charactersContainer.scale = 0.999;

                    this.charactersContainer.setPosition(495, 0);
                    this.scrollableContainer.add(this.charactersContainer);

                    this.characterClimb = this.scene.add.sprite(0, 0, 'climb', 'Climb v20001.png');
                    this.charactersContainer.add(this.characterClimb);

                    this.characterFootSlip = this.scene.add.sprite(0, 326, 'foot-slip-atlas', 'foot slip0001.png');
                    this.charactersContainer.add(this.characterFootSlip);
                    this.characterFootSlip.visible = false;

                    this.characterAdhan = this.scene.add.sprite(0, 0, 'adhan-atlas', 'adhan0001.png');
                    this.charactersContainer.add(this.characterAdhan);
                    this.characterAdhan.visible = false;

                    this._btnClose = new Phaser.GameObjects.Image(this.scene, 1025 - 105, 100 - 50, 'x Button');
                    this._btnClose.setInteractive({ cursor: 'pointer' });
                    this._btnClose["defScale"] = this._btnClose.scale;
                    setupButtonTextureBased(this._btnClose, 'x Button', 'x Button HOVER EFFECT');
                    this.add(this._btnClose);
                    this._btnClose.on('pointerup', () => {
                        playBtnClickAnim(this._btnClose);
                        this.onCloseClick();
                    });
                }
                showGameplay() {
                    setPageBackground("bg-australia");
                    this.bgMusic = this.scene.sound.add("Night ambience");
                    this.bgMusic.play();
                    this.bgMusic.loop = true;
                    this.gameplayContainer = new Phaser.GameObjects.Container(this.scene);
                    this.add(this.gameplayContainer);
                    this.gameplay.reset();
                    this.prepareRound();
                    this.scrollToBottomScreen();
                    this.charactersContainer.y = 0;
                    this.characterClimb.visible = true;
                    this.characterFootSlip.visible = false;
                    this.characterAdhan.visible = false;
                    this.characterClimb.setFrame('Climb v20001.png');
                    this.gameplay.setupCallbacks(this.showCompleteWindow, this.showLoseWindow, () => {
                        this.onNewRound(true);
                    }, () => {
                        this.setInputEnabled(true);
                    });
                    this.createTallies();
                }
                _update() {

                }
                playClimbAnim() {
                    this._climbAnimCompleted = false;
                    this.climbAnimIndex = (this.climbAnimIndex || 0) + 1;
                    this.characterClimb.visible = true;
                    this.characterFootSlip.visible = false;
                    this.characterAdhan.visible = false;
                    this.scene.sound.add("Climbing").play();
                    Preloader.playAnim((this.climbAnimIndex % 2 === 0) ? 'climb-short' : 'climb-long', this.characterClimb, () => {
                        this._climbAnimCompleted = true;

                        if (this._scrollTweenFinished) {
                            this.finishClimbRoutine();
                        }

                        if (this._mosqueTweenFinished) {
                            this.finishMosqueClimbRoutine();
                        }

                    })
                }
                playFootSlipAnim() {
                    this.characterClimb.visible = false;
                    this.characterFootSlip.visible = true;
                    this.characterAdhan.visible = false;
                    Preloader.playAnim('foot-slip', this.characterFootSlip, () => {

                    });
                }
                playAdhanAnim() {
                    this.characterClimb.visible = false;
                    this.characterFootSlip.visible = false;
                    this.characterAdhan.visible = true;
                    Preloader.playAnim('adhan', this.characterAdhan, () => {

                    });
                    delayedCall(1900, () => {
                        this.scene.sound.add("Boy calling Athan").play();
                    })
                }


                prepareRound() {
                    this.gameplayContainer.removeAll();

                    this.passedWordsContainer.removeAll();
                    this.wordsContainer.getAll().forEach(word => this.passedWordsContainer.add(word));
                    this.wordsContainer.removeAll();
                    this.wordsContainer.y = -game.scale.height;

                    this.wantedWindow = null;
                    if (this.gameplay.useImages) {
                        this.wordsContainer.add(new Phaser.GameObjects.Image(this.scene, 370, 460, this.gameplay.wantedWord));
                    } else {
                        let wantedWordTxt = this.scene.add.text(370, 460, "", {
                            "fontFamily": "QuranErafont",
                            "fontSize": 80,
                            "color": "#000000",
                            "align": 'center'
                        });
                        wantedWordTxt.setOrigin(0.5, 0.4);
                        wantedWordTxt.style.fixedHeight = 115;
                        wantedWordTxt.setText(this.gameplay.wantedWord);
                        this.wordsContainer.add(wantedWordTxt);
                    }
                    this.scrollableContainer.add(this.passedWordsContainer);
                    this.scrollableContainer.add(this.wordsContainer);
                    let randomizedWords = Phaser.Utils.Array.Shuffle(this.gameplay.selectableWords.slice());
                    let xPositions = [130, 376.5, 606];
                    this.selectableLetters = [];
                    for (let i = 0; i < randomizedWords.length; i++) {
                        let a = new Phaser.GameObjects.Container(this.scene, xPositions[i], 265);
                        a.add(a["-plankContainer-"] = new Phaser.GameObjects.Container(this.scene, 0, 0));
                        this.selectableLetters.push(a);

                        a['glowRed'] = new Phaser.GameObjects.Image(this.scene, 16, -42, 'red-frame');
                        a["-plankContainer-"].add(a['glowRed']);
                        a['glowRed'].visible = false;

                        a['glowGreen'] = new Phaser.GameObjects.Image(this.scene, 16, -42, 'green-frame');
                        a["-plankContainer-"].add(a['glowGreen']);
                        a['glowGreen'].visible = false;

                        let txt;
                        if (this.gameplay.useImages) {
                            txt = new Phaser.GameObjects.Image(this.scene, 18, -10, randomizedWords[i]);
                            a["-plankContainer-"].add(txt);
                        }
                        else {
                            txt = this.scene.add.text(9, -15, "", {
                                "fontFamily": "QuranErafont",
                                "fontSize": 65,
                                "color": "#000000",
                                "align": 'center'
                            });
                            txt.setOrigin(0.5, 0.45);
                            txt.style.fixedHeight = 90;
                            txt.setText(randomizedWords[i]);
                            a["-plankContainer-"].add(txt);
                        }

                        a['startPosition'] = { x: a.x, y: a.y };
                        a["-word-"] = txt;
                        a["-word-text-"] = randomizedWords[i];
                        this.wordsContainer.add(a);
                        a['-draggable-'] = true;
                    }
                    for (let a of this.selectableLetters) {
                        a.setSize(190, 170);
                        a.setInteractive({ cursor: 'pointer' });
                        a.on('pointerdown', () => {
                            this.setInputEnabled(false);
                            if (a["-word-text-"] == this.gameplay.wantedWord) {
                                a['glowGreen'].visible = true;
                                delayedCall(1500, () => {
                                    a['glowGreen'].visible = false;
                                })
                                this.wantedWindow = a;
                                this.onCorrectAnswer();
                            }
                            else {
                                a['glowRed'].visible = true;
                                delayedCall(1500, () => {
                                    a['glowRed'].visible = false;
                                })
                                this.onWrongAnswer();
                                this.scene.sound.add("Wrong").play();
                            }
                        });
                    }
                    this.wordsContainer.visible = true;
                    this.setInputEnabled(false);
                    if (this.gameplay.isNewRound()) {
                        if (!this.gameplay.isRoundsComplete()) {
                            this.setInputEnabled(true);
                        }
                    }
                    else {
                        this.setInputEnabled(true);
                    }
                    this._btnClose.setInteractive({ cursor: 'pointer', pixelPerfect: true });
                }
                onNewRound(showOut) {
                    this.setInputEnabled(false);
                    if (showOut) {
                        this.prepareRound();
                    }
                }
                onCorrectAnswer() {
                    let i = this.gameplay.getCurrentTotalAnswersCount();
                    this.tallyEmptyArray[i].visible = false;
                    this.tally[i].visible = true;
                    let completed = this.gameplay.onCorrectAnswer();
                    this.scene.sound.add("Correct").play();
                    if (completed) {
                        delayedCall(1500, () => {
                            this.playClimbAnim();
                            this.scrollToMosqueRoofScreen();
                        });
                    } else {
                        delayedCall(1500, () => {
                            this.playClimbAnim();
                            this.scrollToTopScreen();
                        });
                    }
                    return completed;
                }

                onWrongAnswer() {
                    let i = this.gameplay.getCurrentTotalAnswersCount();
                    this.tallyEmptyArray[i].visible = true;
                    this.tallyEmptyArray[i].visible = true;
                    this.tally[i].visible = false;
                    let lost = this.gameplay.onWrongAnswer();
                    this.playFootSlipAnim();
                    delayedCall(350, () => {
                        this.scene.sound.add("Boy slips and hangs").play();
                    });
                    return lost;
                }

                scrollToBottomScreen() {
                    this.scrollableContainer.y = 0;
                    this.wordsContainer.y = 0;
                }

                swapWallBackgrounds() {
                    const topY = this.wallTop.y;
                    this.wallTop.y = this.wallBottom.y;
                    this.wallBottom.y = topY;
                }

                finishClimbRoutine() {
                    if (this._scrollTweenFinished && this._climbAnimCompleted) {
                        this.scrollToBottomScreen();
                        this.swapWallBackgrounds();
                        if (this.climbAnimIndex % 2 === 0) {
                            this.charactersContainer.y += 52;
                        } else {
                            this.charactersContainer.y -= 52;
                        }
                        this.characterClimb.setFrame('Climb v20001.png');
                    }
                }

                finishMosqueClimbRoutine() {
                    if (this._mosqueTweenFinished && this._climbAnimCompleted) {
                        this.scrollableContainer.y = 2 * game.scale.height;
                        this.mosqueRoof.y = -game.scale.height * 1.5;
                        if (this.climbAnimIndex % 2 === 0) {
                            this.charactersContainer.y += 52;
                        } else {
                            this.charactersContainer.y -= 52;
                        }
                        this.charactersContainer.y = -601;
                        this.playAdhanAnim();
                    }
                }

                scrollToTopScreen() {
                    this._scrollTweenFinished = false;
                    this._mosqueTweenFinished = false;
                    const totalDuration = (this.climbAnimIndex % 2 === 0) ? 1550 : 1850;
                    const delayDuration = 400;
                    this.scene.tweens.add({
                        targets: this.scrollableContainer,
                        y: game.scale.height,
                        duration: (totalDuration - delayDuration),
                        delay: delayDuration,
                        ease: Phaser.Math.Easing.Linear,
                        onComplete: () => {
                            this._scrollTweenFinished = true;
                            this.finishClimbRoutine();
                        }
                    });

                    delayedCall(totalDuration * 0.75, () => {
                        this.passedWordsContainer.removeAll();
                    });
                }

                scrollToMosqueRoofScreen() {
                    const totalDuration = (this.climbAnimIndex % 2 === 0) ? 1667 : 2000;
                    const delayDuration = 400;

                    this._scrollTweenFinished = false;
                    this._mosqueTweenFinished = false;
                    this.mosqueRoof.y = -game.scale.height / 2;

                    this.scene.tweens.add({
                        targets: this.scrollableContainer,
                        y: game.scale.height,
                        duration: (totalDuration - delayDuration),
                        delay: delayDuration,
                        ease: Phaser.Math.Easing.Linear,
                        onComplete: () => {
                            this._mosqueTweenFinished = true;
                            this.finishMosqueClimbRoutine();
                        }
                    })
                }
                onCloseClick() {
                    this.showAreYouSurePage();
                    this.scene.sound.add('warning page pop up sfx').play();
                }
                createTallies() {
                    if (this.tally) {
                        for (let te of this.tallyEmptyArray) {
                            te.visible = false;
                            this.bringToTop(te);
                        }
                        for (let t of this.tally) {
                            t.visible = false;
                            this.bringToTop(t);
                        }
                        return;
                    }
                    let startX = 46;
                    let startY = 42;
                    let dx = 55;
                    let dy = 55;
                    let tallyEmptyArrayPositions = [];
                    for (let i = 0; i < 10; i++) {
                        tallyEmptyArrayPositions.push({ x: startX + (i % 5) * dx, y: startY + Math.floor(i / 5) * dy });
                    }

                    this.tallyBar = this.add(new Phaser.GameObjects.Image(this.scene, 160, 70, 'tally bar'));

                    this.tallyEmptyArray = [];
                    this.tally = [];
                    for (let p of tallyEmptyArrayPositions) {
                        let _BeehiveEmpty = new Phaser.GameObjects.Image(this.scene, p['x'], p['y'], 'tally Empty');
                        this.tallyEmptyArray.push(_BeehiveEmpty);
                        _BeehiveEmpty.visible = false;
                        let _Beehive = new Phaser.GameObjects.Image(this.scene, p['x'], p['y'], 'tally');
                        this.tally.push(_Beehive);
                    }
                    for (let bea of this.tallyEmptyArray) {
                        this.add(bea);
                    }
                    for (let bee of this.tally) {
                        this.add(bee);
                        bee.visible = false;
                    }
                }
                hideAllUnusedTallies() {
                    let i = this.gameplay.getCurrentTotalAnswersCount();
                    for (let k = i + 1; k < this.tallyEmptyArray.length; k++) {
                        this.tallyEmptyArray[k].visible = false;
                        this.tally[k].visible = false;
                    }
                }
                showInstructionPage() {
                    setPageBackground("bg-blue");
                    let playInstructionSound = () => {
                        if (this.wfsnd) {
                            this.wfsnd.stop();
                        }
                        this.wfsnd = this.scene.sound.add("Introduction");
                        this.wfsnd.play();
                    };
                    this.instructionPage = new screen.InstructionPage(this.scene, (target) => {
                        playBtnClickAnim(target);
                        this.remove(this.instructionPage);
                        this.showGameplay();
                        if (this.wfsnd) {
                            this.wfsnd.stop();
                        }
                    }, (target) => {
                        playBtnClickAnim(target);
                        playInstructionSound();
                    });
                    this.add(this.instructionPage);
                    try {
                        playInstructionSound();
                    }
                    catch (e) {
                        console.error(e);
                    }
                }
                showAreYouSurePage() {
                    pauseAllDelayedCalls();
                    setPageBackground("bg-blue");
                    this.scene.tweens.pauseAll();
                    this.pauseSounds();
                    this.areYouSureWindow = new screen.AreYouSureWindow(this.scene, () => {
                        this.scene.tweens.resumeAll();
                        this.remove(this.areYouSureWindow);
                        this.destroyGameplay();
                        this.showInstructionPage();
                    }, () => {
                        this.scene.tweens.resumeAll();
                        this.remove(this.areYouSureWindow);
                        this.unpauseSounds();
                        resumeAllDelayedCalls();
                        setPageBackground("bg-australia");
                    });
                    this.add(this.areYouSureWindow);
                }
                setInputEnabled(enabled) {
                    if (enabled) {
                        for (let a of this.selectableLetters)
                            a.setInteractive( /*{cursor: 'pointer', pixelPerfect:true}*/);
                    }
                    else {
                        for (let a of this.selectableLetters)
                            a.disableInteractive();
                    }
                }
                pauseSounds() {
                    this.scene.sound.pauseAll();
                }
                unpauseSounds() {
                    this.scene.sound.resumeAll();
                }
                destroyGameplay() {
                    this.setInputEnabled(false);
                    this.remove(this.gameplayContainer);
                    this.scene.sound.stopAll();
                    destroyAllDelayedCalls();
                }
            }
            screen.GameplayScreen = GameplayScreen;
        })(screen = climbGame.screen || (climbGame.screen = {}));
    })(climbGame || (climbGame = {}));

    (function (climbGame) {
        var screen;
        (function (screen) {
            class InstructionPage extends Phaser.GameObjects.Container {
                constructor(scene, onPlayClick, onSndClick) {
                    super(scene);
                    this._instructionPage = new Phaser.GameObjects.Image(this.scene, 0 - 105, 0 - 48, 'Instructions page  ALL ACTIVITY  TITLEs');
                    this._instructionPage.setOrigin(0, 0);
                    this._instructionPage.setInteractive();

                    this._instructionPageTitle = new Phaser.GameObjects.Image(this.scene, 460, 110, 'Climb the Mosque Title');
                    this._instructionPageTitle.setScale(0.8);


                    this._btnPlay = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2, 480 - 50, 'btnPLAY1');
                    this._btnPlay.setInteractive({ cursor: 'pointer' });
                    this._btnPlay.once('pointerup', onPlayClick);
                    setupButtonTextureBased(this._btnPlay, 'btnPLAY1', 'btnPLAY2');
                    this.instrTxt = this.scene.add.text(game.scale.width / 2, game.scale.height / 2, "Help the boy climb up the mosque and\n\n call the Athaan.", {
                        "fontFamily": "Kids Rock DEMO",
                        "fontSize": 30,
                        "lineSpacing":15,
                        "color": "#43425D",
                        "align": 'center'
                    });
                    this.instrTxt.setOrigin(0.5, 0.5);
                    this.instrTxt.setWordWrapWidth(600);
                    this.instrTxt.setLineSpacing(5);
                    this._btnSoundInstruction = new Phaser.GameObjects.Image(this.scene, 800 - 105, 156 - 50, 'Sound');
                    this._btnSoundInstruction.setInteractive({ cursor: 'pointer' });
                    this._btnSoundInstruction.on('pointerup', onSndClick);
                    this._btnSoundInstruction["defScale"] = this._btnSoundInstruction.scale;
                    setupButtonTextureBased(this._btnSoundInstruction, 'Sound', 'Sound HOVER EFFECT');
                    this.add(this._instructionPage);
                    this.add(this._instructionPageTitle);
                    this.add(this.instrTxt);
                    this.add(this._btnPlay);
                    this.add(this._btnSoundInstruction);
                }
            }
            screen.InstructionPage = InstructionPage;
        })(screen = climbGame.screen || (climbGame.screen = {}));
    })(climbGame || (climbGame = {}));

    (function (climbGame) {
        var screen;
        (function (screen) {
            class TryAgainWindow extends Phaser.GameObjects.Container {
                constructor(scene, onBack, onReplay) {
                    super(scene);
                    this.music = null;
                    this.setPosition(-106, -48);
                    this._bg = new Phaser.GameObjects.Image(this.scene, 0, 0, 'Try again page');
                    this._bg.setOrigin(0, 0);
                    this._bg.setInteractive();
                    this._star = new Phaser.GameObjects.Image(this.scene, 400, 415, 'Break Star');
                    this._btnBack = new Phaser.GameObjects.Image(this.scene, 600, 580, 'btnBACK1');
                    this._btnReplay = new Phaser.GameObjects.Image(this.scene, 765, 580, 'btnReplay1');
                    this.totalScoreTxt = this.scene.add.text(830, 355, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 35,
                        "color": "#F49F1C",
                        "align": 'center',
                        'stroke': '#70451A',
                        'strokeThickness': 6
                    });
                    this.totalScoreTxt.setOrigin(0.5, 0.5);
                    let grd = this.totalScoreTxt.context.createLinearGradient(0, 0, 0, this.totalScoreTxt.height);
                    grd.addColorStop(0, '#FFFF00');
                    grd.addColorStop(1, '#C17316');
                    this.totalScoreTxt.setFill(grd);
                    this.starScoreTxt = this.scene.add.text(635, 431, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 24,
                        "color": "#FFFFFF",
                        "align": 'center'
                    });
                    this.starScoreTxt.setOrigin(0.5, 0.5);
                    this.add([
                        this._bg,
                        this._star,
                        this._btnBack,
                        this._btnReplay,
                        this.totalScoreTxt,
                        this.starScoreTxt
                    ]);
                    this._btnBack.setInteractive({ cursor: 'pointer' });
                    this._btnBack.on('pointerup', () => {
                        onBack(this._btnBack);
                        window.angularComponentRef.backToMenu();
                        // if (this.music) {
                        //     this.music.stop();
                        // }
                    });
                    setupButtonTextureBased(this._btnBack, 'btnBACK1', 'btnBACK2');
                    this._btnReplay.setInteractive({ cursor: 'pointer' });
                    this._btnReplay.once('pointerup', () => {
                        onReplay(this._btnReplay);
                        if (this.music) {
                            this.music.stop();
                        }
                    });
                    setupButtonTextureBased(this._btnReplay, 'btnReplay1', 'btnReplay2');
                }
                show(score, starScore) {
                    this._star.scale = 1.25;
                    this.scene.tweens.add({
                        targets: this._star,
                        "scale": 1,
                        duration: 500,
                        ease: Phaser.Math.Easing.Back.Out
                    });
                    this.totalScoreTxt.text = String(window.angularComponentRef.component.userInfo.totalScore);
                    this.starScoreTxt.text = String(starScore);
                    this.music = this.scene.sound.add("Fail - close one");
                    this.music.play();
                }
            }
            screen.TryAgainWindow = TryAgainWindow;
        })(screen = climbGame.screen || (climbGame.screen = {}));
    })(climbGame || (climbGame = {}));

    game = new climbGame.App(json);
};

function destroyClimbWall() {
    if (game) {
        game.destroy();
    }
}
