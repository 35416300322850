var ctb;
var game;

var crossTheBridge = (json) => {
    (function (ctb) {
        class App extends Phaser.Game {
            constructor(json) {
                App.json = json

                let config = {
                    type: Phaser.AUTO,
                    width: App.CANVAS_WIDTH,
                    height: App.CANVAS_HEIGHT,
                    parent: 'game-container',
                    dom: {
                        createContainer: false
                    },
                    scale: {
                        mode: FitScaleManager.detectBestScaleMode(),
                        autoCenter: Phaser.Scale.CENTER_BOTH
                    },
                    transparent: true,
                    scene: {
                        create: () => {
                            this.scene.add('Boot', ctb.scene.Boot, true);
                        }
                    }
                };
                super(config);
            }
        }
        App.CANVAS_WIDTH = 980;
        App.CANVAS_HEIGHT = 600;
        ctb.App = App;
    })(ctb || (ctb = {}));

    var delayedCalls = [];
    function delayedCall(delay, callback, args, callbackScope) {
        let scene = game.scene.getAt(0);
        if (scene) {
            let dc = scene.time.delayedCall(delay, callback, args, callbackScope);
            delayedCalls.push(dc);
            return dc;
        }
        let t = setTimeout(callback, delay);
        delayedCalls.push(t);
        return t;
    }
    function pauseAllDelayedCalls() {
        for (let dc of delayedCalls) {
            if (dc instanceof Phaser.Time.TimerEvent) {
                dc.paused = true;
            }
        }
    }
    function resumeAllDelayedCalls() {
        for (let dc of delayedCalls) {
            if (dc instanceof Phaser.Time.TimerEvent) {
                dc.paused = false;
            }
        }
    }
    function destroyAllDelayedCalls() {
        for (let dc of delayedCalls) {
            if (dc instanceof Phaser.Time.TimerEvent) {
                dc.remove(false);
            }
            else {
                clearTimeout(dc);
            }
        }
        delayedCalls = [];
    }
    function destroyDelayedCall(dc) {
        if (dc instanceof Phaser.Time.TimerEvent) {
            dc.remove(false);
        }
        else {
            clearTimeout(dc);
        }
        let ind = delayedCalls.indexOf(dc);
        if (ind >= 0)
            delayedCalls.splice(ind, 1);
    }
    function setPageBackground(bg) {
        document.querySelector("html").style.backgroundImage = "url(assets/imgs/" + bg + ".jpg)";
    }
    function setupButton(btn, frame) {
        btn.on('pointerdown', () => { btn.setFrame(frame + '_hover' + '0000'); });
        btn.on('pointerover', () => { btn.setFrame(frame + '_hover' + '0000'); game.scene.getAt(0).sound.add("button hover").play(); });
        btn.on('pointerout', () => { btn.setFrame(frame + '0000'); });
        btn.on('pointerup', () => { btn.setFrame(frame + '0000'); game.scene.getAt(0).sound.add('activity selection - button selection').play(); });
    }
    function setupButtonTextureBased(btn, texture, hoverTexture) {
        btn.on('pointerdown', () => { btn.setTexture(hoverTexture); });
        btn.on('pointerover', () => { btn.setTexture(hoverTexture); game.scene.getAt(0).sound.add("button hover").play(); });
        btn.on('pointerout', () => { btn.setTexture(texture); });
        btn.on('pointerup', () => { btn.setTexture(texture); game.scene.getAt(0).sound.add('activity selection - button selection').play(); });
    }
    function playBtnClickAnim(target) {
        let sc = target.hasOwnProperty("defScale") ? target["defScale"] : 1;
        target.scaleX = target.scaleY = sc;
        let scene = game.scene.getAt(0);
        scene.tweens.add({
            targets: target,
            "scaleX": 0.9 * sc,
            "scaleY": 0.9 * sc,
            duration: 100,
            yoyo: true
        });
    }
    /**
     * @author Roman Parada
     * This class is created to fix overlapping of bottom part of canvas by navigation bar in iOS.
     * It make a delayed resize of the canvas (like Phaser-3 FIT methods does) and it overrides Phaser-3 input window to Phaser-3 core transform methods
     *
     * How to use:
     * Just call the code line below in Boot scene's init() method of your project:
     * new FitScaleManager(this.game).setup();
     */
    class FitScaleManager {
        constructor(game) {
            this.doResize = () => {
                let scale = this.calculateScale();
                let newCanvasWidth = this.phaserScaleManager.width * scale;
                let newCanvasHeight = this.phaserScaleManager.height * scale;
                this.canvasStyle.width = newCanvasWidth + 'px';
                this.canvasStyle.height = newCanvasHeight + 'px';
                this.canvasStyle.marginLeft = (window.innerWidth - newCanvasWidth) / 2 + 'px';
                this.canvasStyle.marginTop = (window.innerHeight - newCanvasHeight) / 2 + 'px';
            };
            this.game = game;
            this.canvasStyle = this.game.canvas.style;
            this.phaserScaleManager = this.game.scale;
        }
        static detectBestScaleMode() {
            let iOS = /iPad|iPhone|iPod/.test(navigator.platform || "");
            let isAndroid = window.navigator.userAgent.toLowerCase().indexOf("android") > -1;
            return iOS || isAndroid ? Phaser.Scale.FIT : Phaser.Scale.NONE;
        }
        ;
        /**
         * Just call this method once in Boot scene's init() method
         */
        setup() {
            this.phaserScaleManager.addListener(Phaser.Scale.Events.RESIZE, this.onResize, this);
            this.overridePhaserTransformMethods();
            this.onResize();
        }
        calculateScale() {
            if (game.scale.scaleMode == Phaser.Scale.NONE)
                return 1;
            return Math.min(window.innerWidth / this.phaserScaleManager.width, window.innerHeight / this.phaserScaleManager.height);
        }
        overridePhaserTransformMethods() {
            this.game.scale.transformX = (pageX) => {
                return (pageX - parseInt(this.canvasStyle.marginLeft.split("px")[0])) / this.calculateScale();
            };
            this.game.scale.transformY = (pageY) => {
                return (pageY - parseInt(this.canvasStyle.marginTop.split("px")[0])) / this.calculateScale();
            };
        }
        onResize() {
            setTimeout(this.doResize, FitScaleManager.RESIZE_DELAY);
        }
    }
    FitScaleManager.RESIZE_DELAY = 500;
    var ctb;
    (function (ctb) {
        var core;
        (function (core) {
            class Gameplay {
                constructor() {
                    this.allLettersNames = null;
                    this.currentRound = 0;
                    this.correctAnswersCount = 0;
                    this.wrongAnswersCount = 0;
                    this.correctAnswersCountThisRound = 0;
                    this.wrongAnswersCountThisRound = 0;
                    this.onNewRound = null;
                    this.nextLetterDelay = 0;
                    this.failsNumToLose = Number(game.cache.json.get('gameplay')["failsNumToLose"]);
                    this.useImages = Boolean(game.cache.json.get('gameplay')["useImages"]);
                }
                setupCallbacks(onComplete, onLose, onNewRound) {
                    this.onComplete = onComplete;
                    this.onLose = onLose;
                    this.onNewRound = onNewRound;
                }
                calculateScore() {
                    return this.totalRoundsNum - this.wrongAnswersCount;
                }
                onLetterChosen() {
                    if (this.correctAnswersCountThisRound == 1) {
                        this.currentRound++;
                        if (this.currentRound >= this.totalRoundsNum) {
                            let score = this.calculateScore();
                            this.onComplete(score, score);
                            return true;
                        }
                        else {
                            this.nextLetter();
                        }
                    }
                    return false;
                }
                nextLetter() {
                    let fn = () => {
                        let thisRound = this.rounds.shift();
                        this.bridgeLetters = thisRound["bridgeLetters"];
                        this.selectableLetters = thisRound["selectableLetters"];
                        this.wordAudioKey = thisRound["wordAudioKey"];
                        this.correctAnswersCountThisRound = 0;
                        this.wrongAnswersCountThisRound = 0;
                        if (this.onNewRound)
                            this.onNewRound();
                    };
                    if (this.nextLetterDelay == 0) {
                        fn();
                    }
                    else {
                        delayedCall(this.nextLetterDelay, fn);
                    }
                }
                onCorrectAnswer() {
                    this.correctAnswersCount++;
                    this.correctAnswersCountThisRound++;
                    this.nextLetterDelay = 4500;
                    return this.onLetterChosen();
                }
                onWrongAnswer() {
                    this.wrongAnswersCount++;
                    this.wrongAnswersCountThisRound++;
                    this.nextLetterDelay = 0;
                    if (this.wrongAnswersCount >= this.failsNumToLose) {
                        this.onLose(0, 0);
                        return true;
                    }
                    else {
                        this.onLetterChosen();
                    }
                    return false;
                }
                getCurrentTotalAnswersCount() {
                    return this.correctAnswersCount + this.wrongAnswersCount;
                }
                getCurrentTotalAnswersCountThisRound() {
                    return this.correctAnswersCountThisRound + this.wrongAnswersCountThisRound;
                }
                isNewRound() {
                    return this.getCurrentTotalAnswersCountThisRound() == 0;
                }
                isRoundsComplete() {
                    return this.currentRound >= this.totalRoundsNum;
                }
                getWordAudioKey() {
                    return this.wordAudioKey;
                }
                reset() {
                    this.nextLetterDelay = 0;
                    this.setupCallbacks(null, null, null);
                    let json = game.cache.json.get('gameplay');
                    this.rounds = json["rounds"].slice();
                    this.totalRoundsNum = this.rounds.length;
                    this.letters = json["letters"].slice();
                    this.allLettersNames = [];
                    for (let d of this.letters)
                        this.allLettersNames.push(d["letterName"]);
                    this.nextLetter();
                    this.currentRound = 0;
                    this.correctAnswersCount = 0;
                    this.wrongAnswersCount = 0;
                    this.correctAnswersCountThisRound = 0;
                    this.wrongAnswersCountThisRound = 0;
                }
            }
            core.Gameplay = Gameplay;
        })(core = ctb.core || (ctb.core = {}));
    })(ctb || (ctb = {}));
    var ctb;
    (function (ctb) {
        var scene;
        (function (scene) {
            class Boot extends Phaser.Scene {
                init() {
                    this.game.scale.transformX = (pageX) => {
                        let offsetLeft = 0;
                        let parentElement = game.canvas.parentElement;
                        while (parentElement) {
                            if (parentElement.offsetLeft) {
                                offsetLeft = parentElement.offsetLeft;
                                break;
                            }
                            parentElement = parentElement.parentElement;
                        }
                        return (pageX - offsetLeft) * this.game.scale.displayScale.x;
                    };
                    this.game.scale.transformY = (pageY) => {
                        let offsetTop = 0;
                        let parentElement = game.canvas.parentElement;
                        while (parentElement) {
                            if (parentElement.offsetTop) {
                                offsetTop = parentElement.offsetTop;
                                break;
                            }
                            parentElement = parentElement.parentElement;
                        }
                        return (pageY - offsetTop) * this.game.scale.displayScale.y;
                    };
                }
                create() {
                    game.scene.remove('Boot');
                    game.scene.add('Preloader', ctb.scene.Preloader, true);
                }
            }
            scene.Boot = Boot;
        })(scene = ctb.scene || (ctb.scene = {}));
    })(ctb || (ctb = {}));
    var ctb;
    (function (ctb) {
        var scene;
        (function (scene) {
            var Gameplay = ctb.core.Gameplay;
            class MainScene extends Phaser.Scene {
                create() {
                    this.gameplay = new Gameplay();
                    this.gameplayScreen = new ctb.screen.GameplayScreen(this, this.gameplay);
                    this.children.add(this.gameplayScreen);
                    this.gameplayScreen.showInstructionPage();
                }
                update() {
                    this.gameplayScreen._update();
                }
            }
            scene.MainScene = MainScene;
        })(scene = ctb.scene || (ctb.scene = {}));
    })(ctb || (ctb = {}));
    var ctb;
    (function (ctb) {
        var scene;
        (function (scene) {
            class Preloader extends Phaser.Scene {
                preload() {
                    this.load.json('gameplay', ctb.App.json);
                }
                create() {
                    let json = game.cache.json.get('gameplay');
                    if (json["useImages"]) {
                        for (let l of json["letters"]) {
                            this.load.image(l["letterName"], "assets/games/cross-the-bridge/imgs/letters/" + l["letterImageKey"] + ".png");
                        }

                        for (let round of json['rounds']) {
                            let word = '';
                            for (let l of round.bridgeLetters) {
                                word += l;
                            }
                            this.load.image(word, "assets/games/cross-the-bridge/imgs/words/" + word + ".png");
                        }
                    }

                    for (let l of json["rounds"]) {
                        this.load.audio(l["wordAudioKey"], "assets/games/cross-the-bridge/sound/mp3/words/" + l["wordAudioKey"].replace(".wav", ".mp3"));
                        this.load.audio('q' + l["wordAudioKey"], "assets/games/cross-the-bridge/sound/mp3/questions/" + l["wordAudioKey"].replace(".wav", ".mp3"));
                    }
                    let progressTxt = this.add.text(game.scale.width / 2, game.scale.height / 2, "", {
                        "fontFamily": "QuranErafont",
                        "fontSize": 25,
                        "color": "#000000",
                        "align": 'center'
                    });
                    progressTxt.setOrigin(0.5, 0.5);
                    this.load.pack('preloader', 'assets/games/cross-the-bridge/pack.json');
                    this.load.on('progress', (value) => {
                        progressTxt.text = Math.ceil(value * 100) + "%";
                    }, this);
                    this.load.on('complete', () => {
                        this.nextScene();
                    });
                    this.load.start();
                }
                static playAnim(animKey, sprite, onComplete = null) {
                    let mainScene = game.scene.getScene('ScreenMain');
                    if (!mainScene.anims.exists(animKey)) {
                        let data = Preloader.ANIMS_DATA[animKey];
                        mainScene.anims.create({
                            key: animKey,
                            frames: mainScene.anims.generateFrameNames(data['atlas'], {
                                start: data['start'], end: data['end'], zeroPad: data['padNum'],
                                prefix: data['prefix'], suffix: ''
                            }),
                            frameRate: data['frameRate'],
                            repeat: data['repeat']
                        });
                    }
                    sprite.off('animationcomplete');
                    sprite.anims.stop();
                    sprite.play(animKey);
                    sprite.on('animationcomplete', () => {
                        if (onComplete)
                            onComplete();
                    });
                    return sprite;
                }
                nextScene() {
                    game.scene.remove('Preloader');
                    game.scene.add('ScreenMain', ctb.scene.MainScene, true);
                }
            }
            Preloader.ANIMS_DATA = {
                'Sprite Files_Happy': {
                    'start': 0,
                    'end': 56,
                    'padNum': 4,
                    'prefix': 'happy',
                    'repeat': 0,
                    'frameRate': 12,
                    'atlas': 'duck-atlass-Sprite Files_Happy'
                },
                'Sprite Files_Iddle': {
                    'start': 0,
                    'end': 73,
                    'padNum': 4,
                    'prefix': 'iddle',
                    'repeat': 0,
                    'frameRate': 12,
                    'atlas': 'duck-Sprite Files_Iddle'
                }
            };
            scene.Preloader = Preloader;
        })(scene = ctb.scene || (ctb.scene = {}));
    })(ctb || (ctb = {}));
    var ctb;
    (function (ctb) {
        var screen;
        (function (screen) {
            class AreYouSureWindow extends Phaser.GameObjects.Container {
                constructor(scene, onYes, onNo) {
                    super(scene);
                    this._areYouSurePage = new Phaser.GameObjects.Image(this.scene, -105, 0 - 48, 'Exit warning');
                    this._areYouSurePage.setOrigin(0, 0);
                    this._areYouSurePage.setInteractive();
                    var modalTextStyle = {
                        backgroundColor: 'transparent',
                        stroke: '#ffffff',
                        strokeThickness: 2,
                        lineSpacing: 15,
                        fontFamily: 'Kids Rock DEMO',
                        fontSize: 27,
                        color: '#43425d',
                        wordWrap: true,
                        align: 'center'
                    };
                    var modalText = this.scene.add.text(game.scale.width / 2 + 12, game.scale.height / 2, "Your progress in this activity will not be saved. \n Are you sure you want to exit? ", modalTextStyle);
                    modalText.setOrigin(0.5, 0.5);
                    this._btnSureYes = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2 - 95, 485 - 50, 'btnYES1');
                    this._btnSureYes.setInteractive({ cursor: 'pointer' });
                    this._btnSureYes.once('pointerup', () => {
                        onYes;
                        window.angularComponentRef.backToMenu();
                    });
                    setupButtonTextureBased(this._btnSureYes, 'btnYES1', 'btnYES2');
                    this._btnSureNo = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2 + 95, 485 - 50, 'btnNO1');
                    this._btnSureNo.setInteractive({ cursor: 'pointer' });
                    this._btnSureNo.once('pointerup', onNo);
                    setupButtonTextureBased(this._btnSureNo, 'btnNO1', 'btnNO2');
                    this.add(this._areYouSurePage);
                    this.add(this._btnSureYes);
                    this.add(this._btnSureNo);
                    this.add(modalText);
                }
            }
            screen.AreYouSureWindow = AreYouSureWindow;
        })(screen = ctb.screen || (ctb.screen = {}));
    })(ctb || (ctb = {}));
    var ctb;
    (function (ctb) {
        var screen;
        (function (screen) {
            class CompleteWindow extends Phaser.GameObjects.Container {
                constructor(scene, onBack, onReplay, onNext) {
                    super(scene);
                    this.music = null;
                    this.setPosition(-104.5, -48);
                    this._bgComplete = new Phaser.GameObjects.Image(this.scene, 0, 0, 'Completion page LATEST UPDATED');
                    this._bgComplete.setOrigin(0, 0);
                    this._bgComplete.setInteractive();
                    this._cup = new Phaser.GameObjects.Image(this.scene, 400, 410, 'Trophy');
                    this._btnBack = new Phaser.GameObjects.Image(this.scene, 570, 570, 'btnBACK1');
                    this._btnReplay = new Phaser.GameObjects.Image(this.scene, 720, 570, 'btnReplay1');
                    this._btnNext = new Phaser.GameObjects.Image(this.scene, 870, 570, 'btnNEXT1');
                    let _CollectedPoints = new Phaser.GameObjects.Image(this.scene, 620, 440, 'Collected Points');
                    this.totalScoreTxt = this.scene.add.text(845, 352, "", {
                        "fontFamily": "Kids Rock DEMO",
                        "fontSize": 35,
                        "color": "#F49F1C",
                        "align": 'center',
                        'stroke': '#70451A',
                        'strokeThickness': 6
                    });
                    this.totalScoreTxt.setOrigin(0.5, 0.5);
                    let grd = this.totalScoreTxt.context.createLinearGradient(0, 0, 0, this.totalScoreTxt.height);
                    grd.addColorStop(0, '#FFFF00');
                    grd.addColorStop(1, '#C17316');
                    this.totalScoreTxt.setFill(grd);
                    this.starScoreTxt = this.scene.add.text(648, 433, "", {
                        "fontFamily": "Kids Rock DEMO",
                        "fontSize": 24,
                        "color": "#FFFFFF",
                        "align": 'center'
                    });
                    this.starScoreTxt.setOrigin(0.5, 0.5);
                    this.add([
                        this._bgComplete,
                        _CollectedPoints,
                        this._cup,
                        this._btnBack,
                        this._btnReplay,
                        this._btnNext,
                        this.totalScoreTxt,
                        this.starScoreTxt
                    ]);
                    this._btnBack.setInteractive({ cursor: 'pointer' });
                    this._btnBack.on('pointerup', () => {
                        onBack(this._btnBack);
                        window.angularComponentRef.backToMenu();
                        // if (this.music) {
                        //     this.music.stop();
                        // }
                    });
                    setupButtonTextureBased(this._btnBack, 'btnBACK1', 'btnBACK2');
                    this._btnReplay.setInteractive({ cursor: 'pointer' });
                    this._btnReplay.once('pointerup', () => {
                        onReplay(this._btnReplay);
                        if (this.music) {
                            this.music.stop();
                        }
                    });
                    setupButtonTextureBased(this._btnReplay, 'btnReplay1', 'btnReplay2');
                    this._btnNext.setInteractive({ cursor: 'pointer' });
                    this._btnNext.on('pointerup', () => {
                        onNext(this._btnNext);
                        window.angularComponentRef.nextActivity();
                        // if (this.music) {
                        //     this.music.stop();
                        // }
                    });
                    setupButtonTextureBased(this._btnNext, 'btnNEXT1', 'btnNEXT2');
                }
                show(score, starScore) {
                    window.angularComponentRef.gameFinish({ score, starScore })
                    this._cup.scale = 1.25;
                    this.scene.tweens.add({
                        targets: this._cup,
                        "scale": 1,
                        duration: 500,
                        ease: Phaser.Math.Easing.Back.Out
                    });
                    this.totalScoreTxt.text = String(window.angularComponentRef.component.userInfo.totalScore);
                    this.starScoreTxt.text = String(starScore);
                    // let music = this.scene.sound.add("viktory");
                    this.music = this.scene.sound.add("Activity completion fantastic");
                    this.music.play();
                }
            }
            screen.CompleteWindow = CompleteWindow;
        })(screen = ctb.screen || (ctb.screen = {}));
    })(ctb || (ctb = {}));
    var ctb;
    (function (ctb) {
        var screen;
        (function (screen) {
            var Preloader = ctb.scene.Preloader;
            class GameplayScreen extends Phaser.GameObjects.Container {
                constructor(scene, gameplay) {
                    super(scene);
                    this.bgMusic = null;
                    this.correctAudio = null;
                    this.playIdle = () => {
                        this.character.setScale(200 / 250 * 1.05);
                        Preloader.playAnim('Sprite Files_Iddle', this.character, this.playIdle);
                    };
                    this.onDuckEnterMosqueSndIndex = 0;
                    this.wfsnd = null;
                    this.showCompleteWindow = (score, starScore) => {
                        delayedCall(1750, () => {
                            this.scene.sound.add("Call to prayer").play();
                        });
                        this.setInputEnabled(false);
                        delayedCall(10500, () => {
                            let completeWindow = new screen.CompleteWindow(this.scene, (target) => {
                                playBtnClickAnim(target);
                            }, (target) => {
                                playBtnClickAnim(target);
                                this.destroyGameplay();
                                this.remove(completeWindow);
                                this.showInstructionPage();
                            }, (target) => {
                                playBtnClickAnim(target);
                            });
                            delayedCall(2000, () => {
                                setPageBackground("bg-blue");
                                this.add(completeWindow);
                                completeWindow.show(score, starScore);
                                this.bgMusic.stop();
                            });
                        });
                    };
                    this.showLoseWindow = (score, starScore) => {
                        let tryAgainWindow = new screen.TryAgainWindow(this.scene, (target) => {
                            playBtnClickAnim(target);
                        }, (target) => {
                            playBtnClickAnim(target);
                            this.destroyGameplay();
                            this.remove(tryAgainWindow);
                            this.showInstructionPage();
                        });
                        this.setInputEnabled(false);
                        delayedCall(2500, () => {
                            setPageBackground("bg-blue");
                            this.add(tryAgainWindow);
                            tryAgainWindow.show(score, starScore);
                            this.bgMusic.stop();
                        });
                    };
                    this.gameplay = gameplay;
                    window["gs"] = this;
                    this._gameStage = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2, game.scale.height / 2, 'BG');
                    this._gameStage.setOrigin(0.5, 0.5);
                    this._gameStage.setScale(1.02);
                    this._gameStage.setInteractive();
                    this.add(this._gameStage);
                    this._btnClose = new Phaser.GameObjects.Image(this.scene, 1025 - 105, 100 - 50, 'x Button');
                    this._btnClose.setInteractive({ cursor: 'pointer' });
                    this._btnClose["defScale"] = this._btnClose.scale;
                    setupButtonTextureBased(this._btnClose, 'x Button', 'x Button HOVER EFFECT');
                    this.add(this._btnClose);
                    this._btnClose.on('pointerup', () => {
                        playBtnClickAnim(this._btnClose);
                        this.onCloseClick();
                    });
                    this._btnSound = new Phaser.GameObjects.Image(this.scene, 160 - 105, 100 - 50, 'Sound');
                    this._btnSound.setInteractive({ cursor: 'pointer' });
                    this._btnSound["defScale"] = this._btnSound.scale;
                    setupButtonTextureBased(this._btnSound, 'Sound', 'Sound HOVER EFFECT');
                    this.add(this._btnSound);
                    this._btnSound.on('pointerup', () => {
                        playBtnClickAnim(this._btnSound);
                        this.onSoundClick();
                    });
                }
                playCorrectAudio() {
                    if (this.correctAudio) {
                        this.correctAudio.stop();
                    }
                    this.correctAudio = this.scene.sound.add('q' + this.gameplay.wordAudioKey);
                    this.correctAudio.play();
                    if (this.areYouSureWindow && this.areYouSureWindow.parentContainer == this) {
                        this.correctAudio.pause();
                    }
                }
                onSoundClick() {
                    this.playCorrectAudio();
                }
                showGameplay() {
                    this.onDuckEnterMosqueSndIndex = 0;
                    setPageBackground("bg-australia");
                    this.bgMusic = this.scene.sound.add("Bachground ambience");
                    this.bgMusic.play();
                    this.bgMusic.loop = true;
                    this.gameplayContainer = new Phaser.GameObjects.Container(this.scene);
                    this.addAt(this.gameplayContainer, this.getIndex(this._btnClose));
                    this.gameplay.reset();
                    this.prepareRound();
                    this.gameplay.setupCallbacks(this.showCompleteWindow, this.showLoseWindow, () => {
                        this.onNewRound(true);
                    });
                    this.createTallies();
                }
                prepareRound() {
                    this.gameplayContainer.removeAll();
                    this.character = this.scene.add.sprite(0, 0, null);
                    this.character.setPosition(190 - 250, 234);
                    delayedCall(250, () => this.scene.sound.add('duck entering the frame').play());
                    this.scene.tweens.add({
                        targets: this.character,
                        x: 190,
                        duration: 1600,
                        ease: Phaser.Math.Easing.Sine.Out,
                        onComplete: () => {
                            this.playCorrectAudio();
                        }
                    });
                    this.character.setScale(1.05);
                    Preloader.playAnim('Sprite Files_Happy', this.character, this.playIdle);
                    this.mosqueLand = new Phaser.GameObjects.Image(this.scene, 750, 275, 'mosque Land');
                    this.gameplayContainer.add(this.mosqueLand);
                    this.gameplayContainer.add(this.mosqueBack = new Phaser.GameObjects.Image(this.scene, 805, 155, 'Mosque'));
                    this.gameplayContainer.add(this.mosqueFront = new Phaser.GameObjects.Image(this.scene, 805, 155, 'MosqueFront'));
                    this.gameplayContainer.add(new Phaser.GameObjects.Image(this.scene, 913, 365, 'tree'));
                    let bridgeLetter = this.gameplay.bridgeLetters.slice();
                    let randomizedLetter = Phaser.Utils.Array.Shuffle(this.gameplay.selectableLetters.slice());
                    this.selectableLetters = [];
                    this.bridgeLetters = [];
                    for (let i = 0; i < bridgeLetter.length; i++) {
                        let b = new Phaser.GameObjects.Container(this.scene, 310 + i * 100, 350);
                        b.add(b['-whileRect-'] = new Phaser.GameObjects.Image(this.scene, ((bridgeLetter.length - 1) / 2 - i) * 6, 0, 'whileRect').setScale(1.04, 0.95));
                        b['-whileRect-'].alpha = 0;
                        this.bridgeLetters.push(b);
                        let l = bridgeLetter[bridgeLetter.length - 1 - i];
                        let txt;
                        if (this.gameplay.useImages) {
                            txt = new Phaser.GameObjects.Image(this.scene, 0, 0, l);

                            b.add(txt);
                        }
                        else {
                            txt = this.scene.add.text(0, -5, "", {
                                "fontFamily": "QuranErafont",
                                "fontSize": 45,
                                "color": "#000000",
                                "align": 'center'
                            });
                            txt.setOrigin(0.5, 0.5);
                            txt.style.fixedHeight = 65;
                            txt.setText(l);
                            b.add(txt);
                        }
                        b["-letter-"] = txt;
                        b["-letter-text"] = l;
                        this.gameplayContainer.add(b);
                    }
                    for (let i = 0; i < randomizedLetter.length; i++) {
                        let a = new Phaser.GameObjects.Container(this.scene, 200 + i * 136, 520);
                        a.add(a["-image-"] = new Phaser.GameObjects.Image(this.scene, 0, 0, 'block'));
                        a["-image-"].setOrigin(0.42, 0.55);
                        a.add(a['-whileRect-'] = new Phaser.GameObjects.Image(this.scene, -6, 0, 'whileRect').setScale(1.04, 0.95));
                        a['-whileRect-'].alpha = 0;
                        this.selectableLetters.push(a);
                        let txt;
                        if (this.gameplay.useImages) {
                            txt = new Phaser.GameObjects.Image(this.scene, 0, 0, randomizedLetter[i]);
                            a.add(txt);
                        }
                        else {
                            txt = this.scene.add.text(0, -5, "", {
                                "fontFamily": "QuranErafont",
                                "fontSize": 45,
                                "color": "#000000",
                                "align": 'center'
                            });
                            txt.setOrigin(0.5, 0.5);
                            txt.style.fixedHeight = 65;
                            txt.setText(randomizedLetter[i]);
                            a.add(txt);
                        }
                        a['startPosition'] = { x: a.x, y: a.y };
                        a["-letter-"] = txt;
                        a["-letter-text"] = randomizedLetter[i];
                        this.gameplayContainer.add(a);
                    }
                    for (let block of this.selectableLetters) {
                        block.setSize(block["-image-"].width, block["-image-"].height);
                        block.setInteractive({ cursor: 'pointer' });
                        block.on('pointerdown', () => {
                            this.setInputEnabled(false);
                            let emptyBlock = this.bridgeLetters[this.bridgeLetters.length - 1];
                            block.disableInteractive();
                            this.placeBlockOnEmptyPlace(block);
                            this.scene.tweens.add({
                                targets: block,
                                x: emptyBlock.x,
                                y: emptyBlock.y,
                                duration: 300,
                                ease: Phaser.Math.Easing.Back.Out
                            });
                            if (block["-letter-text"] == emptyBlock["-letter-text"]) {
                                this.scene.sound.add("Correct click").play();
                                for (let _block of this.selectableLetters) {
                                    if (_block != block) {
                                        this.scene.tweens.add({
                                            targets: _block,
                                            alpha: 0,
                                            duration: 300
                                        });
                                    }
                                }
                                delayedCall(300, () => {
                                    let blocks = this.bridgeLetters.slice();
                                    blocks.splice(blocks.length - 1, 1, block)
                                    let wr = [];
                                    for (let b of blocks) {
                                        wr.push(b['-whileRect-']);
                                    }
                                    this.scene.tweens.add({
                                        targets: wr,
                                        alpha: 1,
                                        duration: 300
                                    });
                                    delayedCall(300, () => {
                                        this.scene.sound.add("Letters joining sound").play();
                                    });
                                    let word = "";
                                    for (let k = blocks.length - 1; k >= 0; k--) {
                                        word += blocks[k]["-letter-text"];
                                        this.scene.tweens.add({
                                            targets: blocks[k]['-letter-'],
                                            x: blocks[k]['-letter-'].x + ((blocks.length - 1) / 2 - k) * 100,
                                            duration: 200,
                                            delay: 200
                                        });
                                    }
                                    let letters = [];
                                    for (let b of blocks) {
                                        letters.push(b['-letter-']);
                                    }
                                    delayedCall(300, () => {
                                        if (this.gameplay.useImages) {
                                            blocks[1]['-letter-'].setTexture(word);
                                        } else {
                                            blocks[1]['-letter-'].setText(word);
                                        }
                                        this.scene.sound.add(this.gameplay.getWordAudioKey()).play();
                                    });
                                });
                                delayedCall(1750, () => {
                                    this.onCorrectAnswer();
                                });
                            }
                            else {
                                let lost = this.onWrongAnswer();
                                this.scene.sound.add("Wrong Answer").play();
                                if (!lost) {
                                    delayedCall(2500, () => {
                                        this.playCorrectAudio();
                                    });
                                }
                                delayedCall(1200, () => {
                                    this.moveBridgeBackToStartPosition(block, () => {
                                        if (!lost) {
                                            delayedCall(2000, () => {
                                                this.setInputEnabled(true);
                                            });
                                        }
                                    });
                                });
                            }
                        });
                    }
                    this._btnClose.setInteractive({ cursor: 'pointer', pixelPerfect: true });
                    this.setInputEnabled(false);
                    if (this.gameplay.isNewRound()) {
                        if (!this.gameplay.isRoundsComplete()) {
                            delayedCall(2000, () => {
                                this.setInputEnabled(true);
                            });
                        }
                    }
                    else {
                        this.setInputEnabled(true);
                    }
                    this.bridgeLetters[2].visible = false;
                    this.gameplayContainer.addAt(this.character, this.gameplayContainer.getIndex(this.mosqueFront));
                }
                _update() {
                    if (this.selectableLetters) {
                        for (let a of this.selectableLetters) {
                            if (a.x < 0 + a["-image-"].width / 2) {
                                a.x = 0 + a["-image-"].width / 2;
                            }
                            else if (a.x > game.scale.width - a["-image-"].width / 2) {
                                a.x = game.scale.width - a["-image-"].width / 2;
                            }
                            if (a.y < 0 + a["-image-"].height / 2) {
                                a.y = a["-image-"].height / 2;
                            }
                            else if (a.y > game.scale.height - a["-image-"].height / 2) {
                                a.y = game.scale.height - a["-image-"].height / 2;
                            }
                        }
                    }
                }
                placeAppleOverBuckets(a) {
                    if (!a.parentContainer)
                        return;
                    this.gameplayContainer.remove(a);
                    this.gameplayContainer.addAt(a, this.gameplayContainer.length);
                }
                placeBlockOnEmptyPlace(b) {
                    this.gameplayContainer.remove(b);
                    this.gameplayContainer.addAt(b, this.gameplayContainer.getIndex(this.mosqueLand));
                }
                onNewRound(showOut) {
                    this.scene.sound.add("next_round").play();
                    this.setInputEnabled(false);
                    if (showOut) {
                        this.prepareRound();
                    }
                }
                moveBridgeBackToStartPosition(block, onComplete) {
                    this.scene.tweens.add({
                        targets: block,
                        x: block['startPosition'].x,
                        y: block['startPosition'].y,
                        duration: 250,
                        ease: Phaser.Math.Easing.Sine.Out,
                        onComplete: () => {
                            if (onComplete)
                                onComplete();
                        }
                    });
                    this.placeAppleOverBuckets(block);
                }
                onCorrectAnswer() {
                    let i = this.gameplay.getCurrentTotalAnswersCount();
                    this.tallyEmptyArray[i].visible = false;
                    this.tally[i].visible = true;
                    let completed = this.gameplay.onCorrectAnswer();
                    this.scene.sound.add("duck and spin sound").play();
                    this.scene.tweens.add({
                        targets: this.character,
                        x: 850,
                        duration: 3300,
                        ease: Phaser.Math.Easing.Sine.Out
                    });
                    delayedCall(2500, () => {
                        if (!this.gameplay.isRoundsComplete()) {
                            this.scene.sound.add(++this.onDuckEnterMosqueSndIndex % 2 == 0 ? "Arabic success word" : "Children Happy Yay").play();
                        }
                    });
                    this.character.setScale(1.05);
                    Preloader.playAnim('Sprite Files_Happy', this.character);
                    return completed;
                }
                onWrongAnswer() {
                    let i = this.gameplay.getCurrentTotalAnswersCount();
                    this.tallyEmptyArray[i].visible = true;
                    this.tallyEmptyArray[i].visible = true;
                    this.tally[i].visible = false;
                    let lost = this.gameplay.onWrongAnswer();
                    return lost;
                }
                onCloseClick() {
                    this.showAreYouSurePage();
                    this.scene.sound.add('warning page pop up sfx').play();
                }
                createTallies() {
                    if (this.tally) {
                        for (let te of this.tallyEmptyArray) {
                            te.visible = false;
                            this.bringToTop(te);
                        }
                        for (let t of this.tally) {
                            t.visible = false;
                            this.bringToTop(t);
                        }
                        return;
                    }
                    let startX = 878 - 105;
                    let startY = 532 - 50;
                    let dx = 53;
                    let dy = 44;
                    let tallyEmptyArrayPositions = [];
                    for (let i = 0; i < 3; i++) {
                        for (let j = 0; j < 4; j++) {
                            tallyEmptyArrayPositions.push({ x: startX + j * dx, y: startY + i * dy });
                        }
                    }
                    this.tallyEmptyArray = [];
                    this.tally = [];
                    for (let p of tallyEmptyArrayPositions) {
                        let _BeehiveEmpty = new Phaser.GameObjects.Image(this.scene, p['x'], p['y'], 'tally Empty');
                        this.tallyEmptyArray.push(_BeehiveEmpty);
                        _BeehiveEmpty.visible = false;
                        let _Beehive = new Phaser.GameObjects.Image(this.scene, p['x'], p['y'], 'tally');
                        this.tally.push(_Beehive);
                    }
                    for (let bea of this.tallyEmptyArray) {
                        this.add(bea);
                    }
                    for (let bee of this.tally) {
                        this.add(bee);
                        bee.visible = false;
                    }
                }
                hideAllUnusedTallies() {
                    let i = this.gameplay.getCurrentTotalAnswersCount();
                    for (let k = i + 1; k < this.tallyEmptyArray.length; k++) {
                        this.tallyEmptyArray[k].visible = false;
                        this.tally[k].visible = false;
                    }
                }
                showInstructionPage() {
                    setPageBackground("bg-blue");
                    let playInstructionSound = () => {
                        if (this.wfsnd) {
                            this.wfsnd.stop();
                        }
                        this.wfsnd = this.scene.sound.add("Help the Yellow Duck");
                        this.wfsnd.play();
                    };
                    this.instructionPage = new screen.InstructionPage(this.scene, (target) => {
                        playBtnClickAnim(target);
                        this.remove(this.instructionPage);
                        this.showGameplay();
                        if (this.wfsnd) {
                            this.wfsnd.stop();
                        }
                    }, (target) => {
                        playBtnClickAnim(target);
                        playInstructionSound();
                    });
                    this.add(this.instructionPage);
                    try {
                        playInstructionSound();
                    }
                    catch (e) {
                        console.error(e);
                    }
                }
                showAreYouSurePage() {
                    pauseAllDelayedCalls();
                    setPageBackground("bg-blue");
                    this.scene.tweens.pauseAll();
                    this.pauseSounds();
                    this.areYouSureWindow = new screen.AreYouSureWindow(this.scene, () => {
                        this.scene.tweens.resumeAll();
                        this.remove(this.areYouSureWindow);
                        this.destroyGameplay();
                        this.showInstructionPage();
                    }, () => {
                        this.scene.tweens.resumeAll();
                        this.remove(this.areYouSureWindow);
                        this.unpauseSounds();
                        resumeAllDelayedCalls();
                        setPageBackground("bg-australia");
                    });
                    this.add(this.areYouSureWindow);
                }
                setInputEnabled(enabled) {
                    if (enabled) {
                        for (let a of this.selectableLetters)
                            a.setInteractive( /*{cursor: 'pointer', pixelPerfect:true}*/);
                    }
                    else {
                        for (let a of this.selectableLetters)
                            a.disableInteractive();
                    }
                }
                pauseSounds() {
                    this.scene.sound.pauseAll();
                }
                unpauseSounds() {
                    this.scene.sound.resumeAll();
                }
                destroyGameplay() {
                    this.setInputEnabled(false);
                    this.remove(this.gameplayContainer);
                    this.scene.sound.stopAll();
                    destroyAllDelayedCalls();
                }
            }
            screen.GameplayScreen = GameplayScreen;
        })(screen = ctb.screen || (ctb.screen = {}));
    })(ctb || (ctb = {}));
    var ctb;
    (function (ctb) {
        var screen;
        (function (screen) {
            class InstructionPage extends Phaser.GameObjects.Container {
                constructor(scene, onPlayClick, onSndClick) {
                    super(scene);
                    this._instructionPage = new Phaser.GameObjects.Image(this.scene, 0 - 105, 0 - 48, 'Instructions page  ALL ACTIVITY  TITLEs');
                    this._instructionPage.setOrigin(0, 0);
                    this._instructionPage.setInteractive();
                    this._instructionPageTitle = new Phaser.GameObjects.Image(this.scene, 495, 105, 'NEWS LETTERS');
                    this._instructionPageTitle.setScale(0.65);
                    this._btnPlay = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2, 480 - 50, 'btnPLAY1');
                    this._btnPlay.setInteractive({ cursor: 'pointer' });
                    this._btnPlay.once('pointerup', onPlayClick);
                    setupButtonTextureBased(this._btnPlay, 'btnPLAY1', 'btnPLAY2');
                    this.instrTxt = this.scene.add.text(game.scale.width / 2, game.scale.height / 2, "Help the Yellow Duck to cross the bridge by completing the word.", {
                        "fontFamily": "Kids Rock DEMO",
                        "fontSize": 30,
                        "color": "#43425D",
                        "align": 'center'
                    });
                    this.instrTxt.setOrigin(0.5, 0.5);
                    this.instrTxt.setWordWrapWidth(650);
                    this.instrTxt.setLineSpacing(11);
                    this._btnSoundInstruction = new Phaser.GameObjects.Image(this.scene, 800 - 105, 156 - 50, 'Sound');
                    this._btnSoundInstruction.setInteractive({ cursor: 'pointer' });
                    this._btnSoundInstruction.on('pointerup', onSndClick);
                    this._btnSoundInstruction["defScale"] = this._btnSoundInstruction.scale;
                    setupButtonTextureBased(this._btnSoundInstruction, 'Sound', 'Sound HOVER EFFECT');
                    this.add(this._instructionPage);
                    this.add(this._instructionPageTitle);
                    this.add(this.instrTxt);
                    this.add(this._btnPlay);
                    this.add(this._btnSoundInstruction);
                }
            }
            screen.InstructionPage = InstructionPage;
        })(screen = ctb.screen || (ctb.screen = {}));
    })(ctb || (ctb = {}));
    var ctb;
    (function (ctb) {
        var screen;
        (function (screen) {
            class TryAgainWindow extends Phaser.GameObjects.Container {
                constructor(scene, onBack, onReplay) {
                    super(scene);
                    this.music = null;
                    this.setPosition(-106, -48);
                    this._bg = new Phaser.GameObjects.Image(this.scene, 0, 0, 'Try again page');
                    this._bg.setOrigin(0, 0);
                    this._bg.setInteractive();
                    this._star = new Phaser.GameObjects.Image(this.scene, 400, 415, 'Break Star');
                    this._btnBack = new Phaser.GameObjects.Image(this.scene, 600, 580, 'btnBACK1');
                    this._btnReplay = new Phaser.GameObjects.Image(this.scene, 765, 580, 'btnReplay1');
                    this.totalScoreTxt = this.scene.add.text(830, 355, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 35,
                        "color": "#F49F1C",
                        "align": 'center',
                        'stroke': '#70451A',
                        'strokeThickness': 6
                    });
                    this.totalScoreTxt.setOrigin(0.5, 0.5);
                    let grd = this.totalScoreTxt.context.createLinearGradient(0, 0, 0, this.totalScoreTxt.height);
                    grd.addColorStop(0, '#FFFF00');
                    grd.addColorStop(1, '#C17316');
                    this.totalScoreTxt.setFill(grd);
                    this.starScoreTxt = this.scene.add.text(635, 431, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 24,
                        "color": "#FFFFFF",
                        "align": 'center'
                    });
                    this.starScoreTxt.setOrigin(0.5, 0.5);
                    this.add([
                        this._bg,
                        this._star,
                        this._btnBack,
                        this._btnReplay,
                        this.totalScoreTxt,
                        this.starScoreTxt
                    ]);
                    this._btnBack.setInteractive({ cursor: 'pointer' });
                    this._btnBack.on('pointerup', () => {
                        onBack(this._btnBack);
                        window.angularComponentRef.backToMenu()
                        // if (this.music) {
                        //     this.music.stop();
                        // }
                    });
                    setupButtonTextureBased(this._btnBack, 'btnBACK1', 'btnBACK2');
                    this._btnReplay.setInteractive({ cursor: 'pointer' });
                    this._btnReplay.once('pointerup', () => {
                        onReplay(this._btnReplay);
                        if (this.music) {
                            this.music.stop();
                        }
                    });
                    setupButtonTextureBased(this._btnReplay, 'btnReplay1', 'btnReplay2');
                }
                show(score, starScore) {
                    this._star.scale = 1.25;
                    this.scene.tweens.add({
                        targets: this._star,
                        "scale": 1,
                        duration: 500,
                        ease: Phaser.Math.Easing.Back.Out
                    });
                    this.totalScoreTxt.text = String(window.angularComponentRef.component.userInfo.totalScore);
                    this.starScoreTxt.text = String(starScore);
                    this.music = this.scene.sound.add("Fail - close one");
                    this.music.play();
                }
            }
            screen.TryAgainWindow = TryAgainWindow;
        })(screen = ctb.screen || (ctb.screen = {}));
    })(ctb || (ctb = {}));

    game = new ctb.App(json);
};

function destroyCrossTheBridge() {
    if (game) {
        game.destroy();
    }
}
