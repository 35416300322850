var game;
var sh;
function soundhive(json) {
    /**
     * @author Roman Parada
     * This class is created to fix overlapping of bottom part of canvas by navigation bar in iOS.
     * It make a delayed resize of the canvas (like Phaser-3 FIT methods does) and it overrides Phaser-3 input window to Phaser-3 core transform methods
     *
     * How to use:
     * Just call the code line below in Boot scene's init() method of your project:
     * new FitScaleManager(this.game).setup();
     */
    class FitScaleManager {
        constructor(game) {
            this.doResize = () => {
                let scale = this.calculateScale();
                let newCanvasWidth = this.phaserScaleManager.width * scale;
                let newCanvasHeight = this.phaserScaleManager.height * scale;
                this.canvasStyle.width = newCanvasWidth + 'px';
                this.canvasStyle.height = newCanvasHeight + 'px';
                this.canvasStyle.marginLeft = (window.innerWidth - newCanvasWidth) / 2 + 'px';
                this.canvasStyle.marginTop = (window.innerHeight - newCanvasHeight) / 2 + 'px';
            };
            this.game = game;
            this.canvasStyle = this.game.canvas.style;
            this.phaserScaleManager = this.game.scale;
        }
        static detectBestScaleMode() {
            let iOS = /iPad|iPhone|iPod/.test(navigator.platform || "");
            let isAndroid = window.navigator.userAgent.toLowerCase().indexOf("android") > -1;
            return iOS || isAndroid ? Phaser.Scale.FIT : Phaser.Scale.NONE;
        }
        ;
        /**
         * Just call this method once in Boot scene's init() method
         */
        setup() {
            this.phaserScaleManager.addListener(Phaser.Scale.Events.RESIZE, this.onResize, this);
            this.overridePhaserTransformMethods();
            this.onResize();
        }
        calculateScale() {
            if (game.scale.scaleMode === Phaser.Scale.NONE)
                return 1;
            return Math.min(window.innerWidth / this.phaserScaleManager.width, window.innerHeight / this.phaserScaleManager.height);
        }
        overridePhaserTransformMethods() {
            this.game.scale.transformX = (pageX) => {
                return (pageX - parseInt(this.canvasStyle.marginLeft.split("px")[0])) / this.calculateScale();
            };
            this.game.scale.transformY = (pageY) => {
                return (pageY - parseInt(this.canvasStyle.marginTop.split("px")[0])) / this.calculateScale();
            };
        }
        onResize() {
            setTimeout(this.doResize, FitScaleManager.RESIZE_DELAY);
        }
    }
    FitScaleManager.RESIZE_DELAY = 500;

    (function (sh) {
        class App extends Phaser.Game {
            constructor(json) {
                App.json = json;

                let config = {
                    type: Phaser.AUTO,
                    width: App.CANVAS_WIDTH,
                    height: App.CANVAS_HEIGHT,
                    parent: 'game-container',
                    dom: {
                        createContainer: false
                    },
                    scale: {
                        mode: FitScaleManager.detectBestScaleMode(),
                        autoCenter: Phaser.Scale.CENTER_BOTH
                    },
                    transparent: true,
                    scene: {
                        create: () => {
                            this.scene.add('Boot', sh.scene.Boot, true);
                        }
                    }
                };
                super(config);
            }
        }
        App.CANVAS_WIDTH = 980;
        App.CANVAS_HEIGHT = 600;
        sh.App = App;
    })(sh || (sh = {}));

    game = new sh.App(json);

    var delayedCalls = [];
    function delayedCall(delay, callback, args, callbackScope) {
        let scene = game.scene.getAt(0);
        if (scene) {
            let dc = scene.time.delayedCall(delay, callback, args, callbackScope);
            delayedCalls.push(dc);
            return dc;
        }
        let t = setTimeout(callback, delay);
        delayedCalls.push(t);
        return t;
    }

    function pauseAllDelayedCalls() {
        for (let dc of delayedCalls) {
            if (dc instanceof Phaser.Time.TimerEvent) {
                dc.paused = true;
            }
        }
    }

    function resumeAllDelayedCalls() {
        for (let dc of delayedCalls) {
            if (dc instanceof Phaser.Time.TimerEvent) {
                dc.paused = false;
            }
        }
    }

    function destroyAllDelayedCalls() {
        for (let dc of delayedCalls) {
            if (dc instanceof Phaser.Time.TimerEvent) {
                dc.remove(false);
            }
            else {
                clearTimeout(dc);
            }
        }
        delayedCalls = [];
    }

    function setPageBackground(bg) {
        // document.querySelector("html").style.backgroundImage = "url(assets/games/soundhive/imgs/" + bg + ".jpg)";
    }

    function setupButton(btn, frame) {
        btn.on('pointerdown', () => { btn.setFrame(frame + '_hover' + '0000'); });
        btn.on('pointerover', () => { btn.setFrame(frame + '_hover' + '0000'); game.scene.getAt(0).sound.add("button hover").play(); });
        btn.on('pointerout', () => { btn.setFrame(frame + '0000'); });
        btn.on('pointerup', () => { btn.setFrame(frame + '0000'); game.scene.getAt(0).sound.add('activity selection - button selection').play(); });
    }

    function setupButtonTextureBased(btn, texture, hoverTexture) {
        btn.on('pointerdown', () => { btn.setTexture(hoverTexture); });
        btn.on('pointerover', () => { btn.setTexture(hoverTexture); game.scene.getAt(0).sound.add("button hover").play(); });
        btn.on('pointerout', () => { btn.setTexture(texture); });
        btn.on('pointerup', () => { btn.setTexture(texture); game.scene.getAt(0).sound.add('activity selection - button selection').play(); });
    }

    (function (sh) {
        var core;
        (function (core) {
            class Gameplay {
                constructor() {
                    this.currentRound = 0;
                    this.currentLetter = 0;
                    this.correctAnswersCount = 0;
                    this.wrongAnswersCount = 0;
                    this.nextLetterDelay = 0;
                    this.reset();
                    let json = game.cache.json.get('gameplay');
                    this.wrongAnswersToLose = json["wrongAnswersToLose"];
                    this.numberOfQuestionsPerRound = json["numberOfQuestionsPerRound"];
                    this.lettersConfig = JSON.parse(JSON.stringify(json["lettersConfig"]));
                    this.rounds = JSON.parse(JSON.stringify(json["rounds"]));
                    this.numberOfRounds = this.rounds.length;
                }
                setupCallbacks(onComplete, onLose) {
                    this.onComplete = onComplete;
                    this.onLose = onLose;
                }
                onLetterChosen() {
                    if (this.isNewRound()) {
                        if (this.currentRound >= this.numberOfRounds - 1) {
                            this.onComplete(this.correctAnswersCount, this.correctAnswersCount);
                            return true;
                        }
                        else {
                            this.nextRound();
                        }
                    }
                    return false;
                }
                nextRound() {
                    let fn = () => {
                        if (this.currentRound == this.rounds.length) {
                            this.currentRound = 0;
                        }
                        else {
                            this.currentRound++;
                        }
                    };
                    if (this.nextLetterDelay == 0) {
                        fn();
                    }
                    else {
                        delayedCall(this.nextLetterDelay, fn);
                    }
                }
                getCorrectLetterName() {
                    return this.rounds[this.currentRound]['correctLetterName'];
                }
                isCorrectLetterAudioQuestionEnable() {
                    return this.rounds[this.currentRound]['audioQuestionEnable'];
                }
                getCorrectAudioKey() {
                    return this.lettersConfig[this.getCorrectLetterName()]['correctAudioKey'];
                }
                getCorrectAndLetterSoundSfxAudioKey() {
                    return this.lettersConfig[this.getCorrectLetterName()]['Sounds of the letters'];
                }
                onCorrectAnswer() {
                    this.correctAnswersCount++;
                    this.nextLetterDelay = 1500;
                    return this.onLetterChosen();
                }
                onWrongAnswer() {
                    this.wrongAnswersCount++;
                    this.nextLetterDelay = 1500;
                    if (this.wrongAnswersCount >= this.wrongAnswersToLose) {
                        this.onLose(this.correctAnswersCount, this.correctAnswersCount);
                        return true;
                    }
                    else {
                        this.onLetterChosen();
                    }
                    return false;
                }
                getCurrentTotalAnswersCount() {
                    return this.correctAnswersCount + this.wrongAnswersCount;
                }
                isNewRound() {
                    return this.getCurrentTotalAnswersCount() % this.numberOfQuestionsPerRound == 0;
                }
                isRoundsComplete() {
                    return this.getCurrentTotalAnswersCount() / this.numberOfQuestionsPerRound >= this.numberOfRounds;
                }
                reset() {
                    this.nextLetterDelay = 0;
                    this.currentRound = 0;
                    this.correctAnswersCount = 0;
                    this.wrongAnswersCount = 0;
                }
                getCurrentRoundLetters() {
                    return this.rounds[this.currentRound]["letters"];
                }
            }
            core.Gameplay = Gameplay;
        })(core = sh.core || (sh.core = {}));
    })(sh || (sh = {}));

    (function (sh) {
        var scene;
        (function (scene) {
            class Boot extends Phaser.Scene {
                init() {
                    this.game.scale.transformX = (pageX) => {
                        let offsetLeft = 0;
                        let parentElement = game.canvas.parentElement;
                        while (parentElement) {
                            if (parentElement.offsetLeft) {
                                offsetLeft = parentElement.offsetLeft;
                                break;
                            }
                            parentElement = parentElement.parentElement;
                        }
                        return (pageX - offsetLeft) * this.game.scale.displayScale.x;
                    };
                    this.game.scale.transformY = (pageY) => {
                        let offsetTop = 0;
                        let parentElement = game.canvas.parentElement;
                        while (parentElement) {
                            if (parentElement.offsetTop) {
                                offsetTop = parentElement.offsetTop;
                                break;
                            }
                            parentElement = parentElement.parentElement;
                        }
                        return (pageY - offsetTop) * this.game.scale.displayScale.y;
                    };
                }
                create() {
                    game.scene.remove('Boot');
                    game.scene.add('Preloader', sh.scene.Preloader, true);
                }
            }
            scene.Boot = Boot;
        })(scene = sh.scene || (sh.scene = {}));
    })(sh || (sh = {}));

    (function (sh) {
        var scene;
        (function (scene) {
            var Gameplay = sh.core.Gameplay;
            class MainScene extends Phaser.Scene {
                create() {
                    this.gameplay = new Gameplay();
                    this.gameplayScreen = new sh.screen.GameplayScreen(this, this.gameplay);
                    this.children.add(this.gameplayScreen);
                    this.gameplayScreen.showInstructionPage();
                }
            }
            scene.MainScene = MainScene;
        })(scene = sh.scene || (sh.scene = {}));
    })(sh || (sh = {}));

    (function (sh) {
        var scene;
        (function (scene) {
            class Preloader extends Phaser.Scene {
                preload() {
                    this.load.json('gameplay', sh.App.json);
                }
                create() {
                    let json = game.cache.json.get('gameplay');
                    let lettersConfig = json["lettersConfig"];

                    for (var i = 0; i < json['secondaryImages'].length; i++) {
                        this.load.image(json['secondaryImages'][i], "assets/games/soundhive/imgs/letters/" + json['secondaryImages'][i] + ".png");
                    }

                    for (let letterName in lettersConfig) {
                        let correctAudioKey = lettersConfig[letterName]["correctAudioKey"];
                        this.load.audio(correctAudioKey, "assets/games/soundhive/sound/mp3/letters/" + correctAudioKey.replace(".wav", ".mp3"));
                        this.load.audio("CS-" + lettersConfig[letterName]["Sounds of the letters"], "assets/games/soundhive/sound/mp3/letters/Sounds of the letters/" + lettersConfig[letterName]["Sounds of the letters"].replace(".wav", ".mp3"));
                    }
                    this.load.pack('preloader', 'assets/games/soundhive/pack.json');
                    this.load.on('progress', (value) => {
                    }, this);
                    this.load.on('complete', () => {
                        this.nextScene();
                    });
                    this.load.start();
                }
                nextScene() {
                    game.scene.remove('Preloader');
                    game.scene.add('ScreenMain', sh.scene.MainScene, true);
                }
            }
            scene.Preloader = Preloader;
        })(scene = sh.scene || (sh.scene = {}));
    })(sh || (sh = {}));
    (function (sh) {
        var screen;
        (function (screen) {
            class AreYouSureWindow extends Phaser.GameObjects.Container {
                constructor(scene, onYes, onNo) {
                    super(scene);
                    this._areYouSurePage = new Phaser.GameObjects.Image(this.scene, -105, 0 - 48, 'Exit warning');
                    this._areYouSurePage.setOrigin(0, 0);
                    this._areYouSurePage.setInteractive();
                    var modalTextStyle = {
                        backgroundColor: 'transparent',
                        stroke: '#ffffff',
                        strokeThickness: 2,
                        lineSpacing: 15,
                        fontFamily: 'Kids Rock DEMO',
                        fontSize: 27,
                        color: '#43425d',
                        wordWrap: true,
                        align: 'center'
                    };
                    var modalText = this.scene.add.text(game.scale.width / 2 + 12, game.scale.height / 2, "Your progress in this activity will not be saved. \n Are you sure you want to exit? ", modalTextStyle);
                    modalText.setOrigin(0.5, 0.5);

                    this._btnSureYes = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2 - 95, 485 - 50, 'btnYES1');
                    this._btnSureYes.setInteractive({ cursor: 'pointer' });
                    this._btnSureYes.once('pointerup', () => {
                        onYes;
                        angularComponentRef.backToMenu();
                    });


                    setupButtonTextureBased(this._btnSureYes, 'btnYES1', 'btnYES2');
                    this._btnSureNo = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2 + 95, 485 - 50, 'btnNO1');
                    this._btnSureNo.setInteractive({ cursor: 'pointer' });
                    this._btnSureNo.once('pointerup', onNo);
                    setupButtonTextureBased(this._btnSureNo, 'btnNO1', 'btnNO2');
                    this.add(this._areYouSurePage);
                    this.add(this._btnSureYes);
                    this.add(this._btnSureNo);
                    this.add(modalText);

                }
            }
            screen.AreYouSureWindow = AreYouSureWindow;
        })(screen = sh.screen || (sh.screen = {}));
    })(sh || (sh = {}));

    (function (sh) {
        var screen;
        (function (screen) {
            class CompleteWindow extends Phaser.GameObjects.Container {
                constructor(scene, onBack, onReplay, onNext) {
                    super(scene);
                    this.music = null;
                    this.setPosition(-104.5, -48);
                    this._bgComplete = new Phaser.GameObjects.Image(this.scene, 0, 0, 'Completion page LATEST UPDATED');
                    this._bgComplete.setOrigin(0, 0);
                    this._bgComplete.setInteractive();
                    this._cup = new Phaser.GameObjects.Image(this.scene, 400, 410, 'Trophy');
                    this._btnBack = new Phaser.GameObjects.Image(this.scene, 570, 570, 'btnBACK1');
                    this._btnReplay = new Phaser.GameObjects.Image(this.scene, 720, 570, 'btnReplay1');
                    this._btnNext = new Phaser.GameObjects.Image(this.scene, 870, 570, 'btnNEXT1');
                    let _CollectedPoints = new Phaser.GameObjects.Image(this.scene, 620, 440, 'Collected Points');
                    this.totalScoreTxt = this.scene.add.text(845, 352, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 35,
                        "color": "#F49F1C",
                        "align": 'center',
                        'stroke': '#70451A',
                        'strokeThickness': 6
                    });
                    this.totalScoreTxt.setOrigin(0.5, 0.5);
                    let grd = this.totalScoreTxt.context.createLinearGradient(0, 0, 0, this.totalScoreTxt.height);
                    grd.addColorStop(0, '#FFFF00');
                    grd.addColorStop(1, '#C17316');
                    this.totalScoreTxt.setFill(grd);
                    this.starScoreTxt = this.scene.add.text(648, 433, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 24,
                        "color": "#FFFFFF",
                        "align": 'center'
                    });
                    this.starScoreTxt.setOrigin(0.5, 0.5);
                    this.add([
                        this._bgComplete,
                        _CollectedPoints,
                        this._cup,
                        this._btnBack,
                        this._btnReplay,
                        this._btnNext,
                        this.totalScoreTxt,
                        this.starScoreTxt
                    ]);
                    this._btnBack.setInteractive({ cursor: 'pointer' });
                    this._btnBack.on('pointerup', () => {
                        onBack(this._btnBack);
                        angularComponentRef.backToMenu();
                        // if (this.music) {
                        //     this.music.stop();
                        // }
                    });
                    setupButtonTextureBased(this._btnBack, 'btnBACK1', 'btnBACK2');
                    this._btnReplay.setInteractive({ cursor: 'pointer' });
                    this._btnReplay.once('pointerup', () => {
                        onReplay(this._btnReplay);
                        if (this.music) {
                            this.music.stop();
                        }
                    });
                    setupButtonTextureBased(this._btnReplay, 'btnReplay1', 'btnReplay2');
                    this._btnNext.setInteractive({ cursor: 'pointer' });
                    this._btnNext.on('pointerup', () => {
                        onNext(this._btnNext);
                        window.angularComponentRef.nextActivity()
                    });
                    setupButtonTextureBased(this._btnNext, 'btnNEXT1', 'btnNEXT2');
                }
                show(score, starScore) {
                    window.angularComponentRef.gameFinish({ score, starScore })
                    this._cup.scale = 1.25;
                    this.scene.tweens.add({
                        targets: this._cup,
                        "scale": 1,
                        duration: 500,
                        ease: Phaser.Math.Easing.Back.Out
                    });
                    this.totalScoreTxt.text = String(window.angularComponentRef.component.userInfo.totalScore);
                    this.starScoreTxt.text = String(starScore);
                    // let music = this.scene.sound.add("viktory");
                    this.music = this.scene.sound.add("Activity completion fantastic");
                    this.music.play();
                }
            }
            screen.CompleteWindow = CompleteWindow;
        })(screen = sh.screen || (sh.screen = {}));
    })(sh || (sh = {}));

    (function (sh) {
        var screen;
        (function (screen) {
            class GameplayScreen extends Phaser.GameObjects.Container {
                constructor(scene, gameplay) {
                    super(scene);
                    this.hexagonPositions = [{ x: 595 - 105, y: 152 - 50 }, { x: 595 - 105, y: 500 - 50 }, { x: 422 - 105, y: 240 - 50 }, { x: 595 - 105, y: 326 - 50 }, { x: 768 - 105, y: 240 - 50 }, { x: 422 - 105, y: 414 - 50 }, { x: 770 - 105, y: 414 - 50 }];
                    this.bgMusic = null;
                    this.correctAudio = null;
                    this.wfsnd = null;
                    this.showCompleteWindow = (score, starScore) => {
                        let completeWindow = new screen.CompleteWindow(this.scene, (target) => {
                            this.playBtnClickAnim(target);
                        }, (target) => {
                            this.playBtnClickAnim(target);
                            this.destroyGameplay();
                            this.remove(completeWindow);
                            this.showInstructionPage();
                        }, (target) => {
                            this.playBtnClickAnim(target);
                        });
                        this.setLettersInputEnabled(false);
                        delayedCall(1750, () => {
                            setPageBackground("bg-blue");
                            this.add(completeWindow);
                            completeWindow.show(score, starScore);
                            this.bgMusic.stop();
                        });
                    };
                    this.showLoseWindow = (score, starScore) => {
                        let tryAgainWindow = new screen.TryAgainWindow(this.scene, (target) => {
                            this.playBtnClickAnim(target);
                        }, (target) => {
                            this.playBtnClickAnim(target);
                            this.destroyGameplay();
                            this.remove(tryAgainWindow);
                            this.showInstructionPage();
                        });
                        this.setLettersInputEnabled(false);
                        delayedCall(1750, () => {
                            setPageBackground("bg-blue");
                            this.add(tryAgainWindow);
                            tryAgainWindow.show(score, starScore);
                            this.bgMusic.stop();
                        });
                    };
                    this.gameplay = gameplay;
                    window["t"] = this;
                }
                createHexagons() {
                    this.hexagons = [];
                    for (let p of this.hexagonPositions) {
                        let hc = new Phaser.GameObjects.Container(this.scene, this.hexagonPositions[3]['x'], this.hexagonPositions[3]['y']);
                        let h = new Phaser.GameObjects.Image(this.scene, 0, 0, 'hexagon');
                        hc.add(h);
                        hc["bg"] = h;
                        hc["_hexagon_correct"] = new Phaser.GameObjects.Image(this.scene, 0, 0, '1 Correct Hover Effects');
                        hc.add(hc["_hexagon_correct"]);
                        hc["_hexagon_wrong"] = new Phaser.GameObjects.Image(this.scene, 0, 0, '1 wrong Hover Effects');
                        hc.add(hc["_hexagon_wrong"]);
                        hc["_hexagon_correct"].visible = hc["_hexagon_wrong"].visible = false;
                        hc["defX"] = p['x'];
                        hc["defY"] = p['y'];
                        this.hexagons.push(hc);
                    }
                    this.gameplayContainer.add(this.hexagons);
                }
                createLetters() {
                    if (this.letters) {
                        for (let l of this.letters) {
                            l.parentContainer["letter"] = null;
                            l["hexagon"] = null;
                            l.parentContainer.remove(l);
                        }
                    }
                    this.letters = [];
                    let _letters = this.gameplay.getCurrentRoundLetters();
                    for (let n of _letters) {
                        let l = new Phaser.GameObjects.Image(this.scene, 0, 0, n);
                        this.letters.push(l);
                    }
                }
                showGameplay() {
                    setPageBackground("bg-australia");
                    this.bgMusic = this.scene.sound.add("bg_sound");
                    this.bgMusic.play();
                    this.bgMusic.loop = true;
                    this.gameplay.reset();
                    this._gameStage = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2, game.scale.height / 2, 'Sound Hive Final');
                    this._gameStage.setOrigin(0.488, 0.476);
                    this._gameStage.setInteractive();
                    let rr = new Phaser.GameObjects.Image(this.scene, 958 - 105, 570 - 50, 'Rounded Rectangle 602');
                    rr.setScale(0.75, 1.4);
                    this._btnSound = new Phaser.GameObjects.Image(this.scene, 160 - 105, 100 - 50, 'Sound');
                    this._btnSound.setInteractive({ cursor: 'pointer' });
                    this._btnSound["defScale"] = this._btnSound.scale;
                    setupButtonTextureBased(this._btnSound, 'Sound', 'Sound HOVER EFFECT');
                    this._btnClose = new Phaser.GameObjects.Image(this.scene, 1025 - 105, 100 - 50, 'x Button');
                    this._btnClose.setInteractive({ cursor: 'pointer' });
                    this._btnClose["defScale"] = this._btnClose.scale;
                    setupButtonTextureBased(this._btnClose, 'x Button', 'x Button HOVER EFFECT');
                    this.gameplayContainer = new Phaser.GameObjects.Container(this.scene);
                    this.addAt(this.gameplayContainer, 0);
                    this.gameplayContainer.add([
                        this._gameStage,
                        rr,
                        this._btnSound,
                        this._btnClose
                    ]);
                    this.createBeehives();
                    this.createHexagons();
                    this.shuffleOutHexagons();
                    this.createInput();
                    this.gameplay.setupCallbacks(this.showCompleteWindow, this.showLoseWindow);
                }
                createBeehives() {
                    let startX = 878 - 105;
                    let startY = 532 - 50;
                    let dx = 39;
                    let dy = 39;
                    let _BeehiveEmptyArrayPositions = [];
                    for (let i = 0; i < 3; i++) {
                        for (let j = 0; j < 5; j++) {
                            _BeehiveEmptyArrayPositions.push({ x: startX + j * dx, y: startY + i * dy });
                        }
                    }
                    this._BeehiveEmptyArray = [];
                    this._Beehive = [];
                    for (let p of _BeehiveEmptyArrayPositions) {
                        let _BeehiveEmpty = new Phaser.GameObjects.Image(this.scene, p['x'], p['y'], 'Beehive 1 Empty');
                        this._BeehiveEmptyArray.push(_BeehiveEmpty);
                        _BeehiveEmpty.visible = false;
                        let _Beehive = new Phaser.GameObjects.Image(this.scene, p['x'], p['y'], 'Beehive 1');
                        this._Beehive.push(_Beehive);
                    }
                    for (let bea of this._BeehiveEmptyArray) {
                        this.gameplayContainer.add(bea);
                    }
                    for (let bee of this._Beehive) {
                        this.gameplayContainer.add(bee);
                        bee.visible = false;
                    }
                }
                randomizeLettersPosition() {
                    let hx = this.hexagons.slice();
                    for (let l of this.letters) {
                        if (hx.length == 0)
                            return;
                        let hc = Phaser.Utils.Array.RemoveRandomElement(hx);
                        hc.add(l);
                        hc["letter"] = l;
                        l["hexagon"] = hc;
                    }
                }
                updateHexagonsArrange() {
                    for (let hc of this.hexagons) {
                        if (!hc["letter"]) {
                            hc.parentContainer.bringToTop(hc);
                            break;
                        }
                    }
                }
                shuffleOutHexagons() {
                    if (this.gameplay.isNewRound()) {
                        if (!this.gameplay.isRoundsComplete()) {
                            this.createLetters();
                            this.randomizeLettersPosition();
                        }
                    }

                    this.setLettersInputEnabled(false);
                    this.updateHexagonsArrange();
                    let positions = this.hexagonPositions.slice();
                    for (let hc of this.hexagons) {
                        let rndP = Phaser.Utils.Array.RemoveRandomElement(positions);
                        this.scene.tweens.add({
                            targets: hc,
                            "x": rndP['x'],
                            "y": rndP['y'],
                            duration: 300,
                            delay: 500
                        });
                    }
                    delayedCall(450, () => {
                        this.scene.sound.add('spread from centre').play();
                    });
                    delayedCall(1200, () => {
                        let setLettersInputEnabled = () => {
                            if (this.correctAudio && this.correctAudio.isPlaying) {
                                delayedCall((this.correctAudio.duration * 0.75) * 1000, () => {
                                    this.setLettersInputEnabled(true);
                                });
                            }
                            else {
                                this.setLettersInputEnabled(true);
                            }
                        };
                        if (this.gameplay.isNewRound()) {
                            if (!this.gameplay.isRoundsComplete()) {
                                if (this.gameplay.isCorrectLetterAudioQuestionEnable()) {
                                    this.playCorrectAudio();
                                }
                                setLettersInputEnabled();
                            }
                        }
                        else {
                            setLettersInputEnabled();
                        }
                    });
                }
                shuffleInHexagons() {
                    this.updateHexagonsArrange();
                    let positions = this.hexagonPositions.slice();
                    this.setLettersInputEnabled(false);
                    for (let hc of this.hexagons) {
                        let posX = hc.x;
                        let posY = hc.y;
                        this.scene.tweens.add({
                            targets: hc,
                            "x": this.hexagonPositions[3]['x'],
                            "y": this.hexagonPositions[3]['y'],
                            duration: 300,
                            delay: 700,
                            onComplete: () => {
                                hc["_hexagon_correct"].visible = hc["_hexagon_wrong"].visible = false;
                            }
                        });
                    }
                    delayedCall(600, () => {
                        this.scene.sound.add('move to centre').play();
                    });
                    delayedCall(1000, () => {
                        this.shuffleOutHexagons();
                    });
                }
                createInput() {
                    for (let hc of this.hexagons) {
                        hc["bg"].on('pointerup', () => {
                            let l = hc["letter"];
                            this.setLettersInputEnabled(false);
                            this.playBtnClickAnim(hc);
                            let lost = false;
                            let correctAnswer = l && l.texture.key == this.gameplay.getCorrectLetterName();
                            if (correctAnswer) {
                                hc["_hexagon_wrong"].visible = false;
                                hc["_hexagon_correct"].visible = true;
                                let completed = this.onCorrectAnswer();
                                if (!completed) {
                                    delayedCall(1000, () => this.shuffleInHexagons());
                                }
                            }
                            else {
                                hc["_hexagon_wrong"].visible = true;
                                hc["_hexagon_correct"].visible = false;
                                lost = this.onWrongAnswer();
                                if (!lost) {
                                    delayedCall(1000, () => this.shuffleInHexagons());
                                }
                            }
                            if (!lost) {
                                delayedCall(2500, () => {
                                    if (!this.gameplay.isNewRound()) {
                                        if (this.gameplay.isCorrectLetterAudioQuestionEnable()) {
                                            this.playCorrectAudio();
                                        }
                                    }
                                });
                            }
                        });
                    }
                    this._btnSound.on('pointerup', () => {
                        this.playBtnClickAnim(this._btnSound);
                        this.onSoundClick();
                    });
                    this._btnClose.on('pointerup', () => {
                        this.playBtnClickAnim(this._btnClose);
                        this.onCloseClick();
                    });
                }
                hideAllUnusedBeehives() {
                    let i = this.gameplay.getCurrentTotalAnswersCount();
                    for (let k = i + 1; k < this._BeehiveEmptyArray.length; k++) {
                        this._BeehiveEmptyArray[k].visible = false;
                        this._Beehive[k].visible = false;
                    }
                }
                onCorrectAnswer() {
                    let i = this.gameplay.getCurrentTotalAnswersCount();
                    if (i < this._BeehiveEmptyArray.length) {
                        this._BeehiveEmptyArray[i].visible = false;
                        this._Beehive[i].visible = true;
                    }
                    this.hideAllUnusedBeehives();
                    let completed = this.gameplay.onCorrectAnswer();
                    this.scene.sound.add('correct and letter sound sfx').play();
                    delayedCall(750, () => {
                        this.scene.sound.add("CS-" + this.gameplay.getCorrectAndLetterSoundSfxAudioKey()).play();
                    });
                    return completed;
                }
                onWrongAnswer() {
                    let i = this.gameplay.getCurrentTotalAnswersCount();
                    if (i < this._BeehiveEmptyArray.length) {
                        this._BeehiveEmptyArray[i].visible = true;
                        this._Beehive[i].visible = false;
                    }
                    this.hideAllUnusedBeehives();
                    let lost = this.gameplay.onWrongAnswer();
                    this.scene.sound.add("wrong sfx").play();
                    return lost;
                }
                playCorrectAudio() {
                    if (this.correctAudio) {
                        this.correctAudio.stop();
                    }
                    this.correctAudio = this.scene.sound.add(this.gameplay.getCorrectAudioKey());
                    this.correctAudio.play();
                    if (this.areYouSureWindow && this.areYouSureWindow.parentContainer == this) {
                        this.correctAudio.pause();
                    }
                }
                onSoundClick() {
                    this.playCorrectAudio();
                }
                onCloseClick() {
                    this.showAreYouSurePage();
                    this.scene.sound.add('warning page pop up sfx').play();
                }
                playBtnClickAnim(target) {
                    let sc = target["defScale"] ? target["defScale"] : 1;
                    target.scaleX = target.scaleY = sc;
                    this.scene.tweens.add({
                        targets: target,
                        "scaleX": 0.9 * sc,
                        "scaleY": 0.9 * sc,
                        duration: 100,
                        yoyo: true
                    });
                }
                showInstructionPage() {
                    setPageBackground("bg-blue");
                    this.instructionPage = new screen.InstructionPage(this.scene, (target) => {
                        this.playBtnClickAnim(target);
                        this.remove(this.instructionPage);
                        this.showGameplay();
                        if (this.wfsnd) {
                            this.wfsnd.stop();
                        }
                    }, (target) => {
                        this.playBtnClickAnim(target);
                        if (this.wfsnd) {
                            this.wfsnd.stop();
                        }
                        this.wfsnd = this.scene.sound.add("Welcome Find the sound");
                        this.wfsnd.play();
                    });
                    this.add(this.instructionPage);
                    try {
                        this.wfsnd = this.scene.sound.add("Welcome Find the sound");
                        this.wfsnd.play();
                    }
                    catch (e) {
                        console.error(e);
                    }
                }
                showAreYouSurePage() {
                    pauseAllDelayedCalls();
                    setPageBackground("bg-blue");
                    this.pauseSounds();
                    this.areYouSureWindow = new screen.AreYouSureWindow(this.scene, () => {
                        this.remove(this.areYouSureWindow);
                        this.destroyGameplay();
                        this.showInstructionPage();
                    }, () => {
                        this.remove(this.areYouSureWindow);
                        this.unpauseSounds();
                        resumeAllDelayedCalls();
                        setPageBackground("bg-australia");
                    });
                    this.add(this.areYouSureWindow);
                }
                setLettersInputEnabled(enabled) {
                    if (enabled) {
                        for (let hc of this.hexagons) {
                            hc["bg"].setInteractive({ cursor: 'pointer' });
                        }
                    }
                    else {
                        for (let hc of this.hexagons) {
                            hc["bg"].disableInteractive();
                        }
                    }
                }
                pauseSounds() {
                    if (this.correctAudio)
                        this.correctAudio.pause();
                    if (this.bgMusic)
                        this.bgMusic.pause();
                }
                unpauseSounds() {
                    if (this.correctAudio)
                        this.correctAudio.resume();
                    if (this.bgMusic)
                        this.bgMusic.resume();
                }
                destroyGameplay() {
                    this.setLettersInputEnabled(false);
                    this.remove(this.gameplayContainer);
                    destroyAllDelayedCalls();
                }
            }
            screen.GameplayScreen = GameplayScreen;
        })(screen = sh.screen || (sh.screen = {}));
    })(sh || (sh = {}));
    (function (sh) {
        var screen;
        (function (screen) {
            class InstructionPage extends Phaser.GameObjects.Container {
                constructor(scene, onPlayClick, onSndClick) {
                    super(scene);
                    this._instructionPage = new Phaser.GameObjects.Image(this.scene, 0 - 105, 0 - 48, 'Instructions page  ALL ACTIVITY  TITLEs');
                    this._instructionPage.setOrigin(0, 0);
                    this._instructionPage.setInteractive();
                    this._instructionPageTitle = new Phaser.GameObjects.Image(this.scene, 495, 105, 'welcome');
                    this._instructionPageTitle.setScale(0.8);
                    this._btnPlay = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2, 480 - 50, 'btnPLAY1');
                    this._btnPlay.setInteractive({ cursor: 'pointer' });
                    this._btnPlay.once('pointerup', onPlayClick);
                    setupButtonTextureBased(this._btnPlay, 'btnPLAY1', 'btnPLAY2');
                    this.instrTxt = this.scene.add.text(game.scale.width / 2, game.scale.height / 2, "Help the bee find the sound.", {
                        "fontFamily": "Kids Rock DEMO",
                        "fontSize": 30,
                        "color": "#43425D",
                        "align": 'center'
                    });
                    this.instrTxt.setOrigin(0.5, 0.5);
                    this._btnSoundInstruction = new Phaser.GameObjects.Image(this.scene, 800 - 105, 156 - 50, 'Sound');
                    this._btnSoundInstruction.setInteractive({ cursor: 'pointer' });
                    this._btnSoundInstruction.on('pointerup', onSndClick);
                    this._btnSoundInstruction["defScale"] = this._btnSoundInstruction.scale;
                    setupButtonTextureBased(this._btnSoundInstruction, 'Sound', 'Sound HOVER EFFECT');
                    this.add(this._instructionPage);
                    this.add(this._instructionPageTitle);
                    this.add(this.instrTxt);
                    this.add(this._btnPlay);
                    this.add(this._btnSoundInstruction);
                }
            }
            screen.InstructionPage = InstructionPage;
        })(screen = sh.screen || (sh.screen = {}));
    })(sh || (sh = {}));
    (function (sh) {
        var screen;
        (function (screen) {
            class TryAgainWindow extends Phaser.GameObjects.Container {
                constructor(scene, onBack, onReplay) {
                    super(scene);
                    this.music = null;
                    this.setPosition(-106, -48);
                    this._bg = new Phaser.GameObjects.Image(this.scene, 0, 0, 'Try again page');
                    this._bg.setOrigin(0, 0);
                    this._bg.setInteractive();
                    this._star = new Phaser.GameObjects.Image(this.scene, 400, 415, 'Break Star');
                    this._btnBack = new Phaser.GameObjects.Image(this.scene, 600, 580, 'btnBACK1');
                    this._btnReplay = new Phaser.GameObjects.Image(this.scene, 765, 580, 'btnReplay1');
                    this.totalScoreTxt = this.scene.add.text(830, 355, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 35,
                        "color": "#F49F1C",
                        "align": 'center',
                        'stroke': '#70451A',
                        'strokeThickness': 6
                    });
                    this.totalScoreTxt.setOrigin(0.5, 0.5);
                    let grd = this.totalScoreTxt.context.createLinearGradient(0, 0, 0, this.totalScoreTxt.height);
                    grd.addColorStop(0, '#FFFF00');
                    grd.addColorStop(1, '#C17316');
                    this.totalScoreTxt.setFill(grd);
                    this.starScoreTxt = this.scene.add.text(635, 431, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 24,
                        "color": "#FFFFFF",
                        "align": 'center'
                    });
                    this.starScoreTxt.setOrigin(0.5, 0.5);
                    this.add([
                        this._bg,
                        this._star,
                        this._btnBack,
                        this._btnReplay,
                        this.totalScoreTxt,
                        this.starScoreTxt
                    ]);
                    this._btnBack.setInteractive({ cursor: 'pointer' });
                    this._btnBack.on('pointerup', () => {
                        onBack(this._btnBack);
                        angularComponentRef.backToMenu();
                        // if (this.music) {
                        //     this.music.stop();
                        // }
                    });
                    setupButtonTextureBased(this._btnBack, 'btnBACK1', 'btnBACK2');
                    this._btnReplay.setInteractive({ cursor: 'pointer' });
                    this._btnReplay.once('pointerup', () => {
                        onReplay(this._btnReplay);
                        if (this.music) {
                            this.music.stop();
                        }
                    });
                    setupButtonTextureBased(this._btnReplay, 'btnReplay1', 'btnReplay2');
                }
                show(score, starScore) {
                    this._star.scale = 1.25;
                    this.scene.tweens.add({
                        targets: this._star,
                        "scale": 1,
                        duration: 500,
                        ease: Phaser.Math.Easing.Back.Out
                    });
                    this.totalScoreTxt.text = String(window.angularComponentRef.component.userInfo.totalScore);
                    this.starScoreTxt.text = String(0);
                    this.music = this.scene.sound.add("Fail - close one");
                    this.music.play();
                }
            }
            screen.TryAgainWindow = TryAgainWindow;
        })(screen = sh.screen || (sh.screen = {}));
    })(sh || (sh = {}));
}

function destroySoundHive() {
    if (game) {
        game.destroy();
    }
}
